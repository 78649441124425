import React from "react";
import {useState} from "react"
import henceforthApi from "../utils/henceforthApi";

function ForgotPassword({ navigate }:any) {

  const[email,setEmail]=useState("")
  const ForgotPassword=async()=>{
let ApiRes=await henceforthApi.ForgotPassword.email({email:email})
  }
  return (
    <div className="passwordBox animated fadeInDown">
      <div className="row">
        <div className="col-md-12">
          <div className="ibox-content">
            <h2 className="font-bold">Forgot password</h2>

            <p>Enter your email address and your password will be reset and emailed to you.</p>

            <div className="row">
              <div className="col-lg-12">
                <form className="m-t" role="form" onSubmit={(e:any)=>{e.preventDefault();ForgotPassword()}}>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email address"
                      value={email}
                      required
                      onChange={(e:any)=>{setEmail(e.target.value)}}
                    />
                  </div>

                  <button type="submit" className="btn btn-primary block full-width m-b" >
                    Send new password
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
