import React from "react"
interface chip{
    name:string
    _id:string, 
    onClick:(id:string,name:string)=> void
}
export const Spinner = (props:any) => {

    return <div className={`spinner-border spinner-grow-sm`}  role="status">
        <span className="visually-hidden"></span>
    </div>
}

export const GlobalLoading = () => {
    return <div className="modal fade steps show" id="exampleModalLive" tabIndex={-1}aria-labelledby="exampleModalLiveLabel" aria-modal="true" role="dialog" style={{ display: "block" }}>
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-body">
                    <div className="nft-body-modal">
                        <div className="upload-stepss">
                            <div className="step-img">
                                <Spinner />
                            </div>
                            <div className="step-text">
                                <h2>Loading...</h2>
                                <p>Please Wait!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
export const ChipButton = ({ _id, name, onClick }:chip) => {
    return <button type="button" className="btn btn-aus bg-light mx-1" onClick={() => onClick(_id, name)}>{name} <i className="fal fa-times-circle ml-1"></i></button>
}