import { Link, useMatch } from "react-router-dom";
import React, { useState, useContext, useEffect } from "react";
import { GlobalContext } from "../context/Provider";
import henceforthApi from "../utils/henceforthApi";
import { Spinner } from "../components/BootstrapCompo";
interface Policies {
  _id: string,
  description: string,
  type: string
}
export default () => {
  const { authState, handleError } = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    data: [] as Array<Policies>,
    total_count: 0,
  });
  if (authState.access_token) { henceforthApi.setToken(authState.access_token); }
  const initialiseData = async () => {
    try {
      setLoading(true)
      let apiRes = await henceforthApi.Policies.all();
      setState({
        ...state,
        ...apiRes,
      });
      console.log(apiRes.data);
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      handleError(error)
    }
  };
  useEffect(() => {
    initialiseData();
  }, []);
  return (
    <div className="ibox animated fadeInRight">
      <div className="ibox-title">
        <h5>Contents</h5>
      </div>
      <div className="ibox-content ">
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Sr No.</th>
                <th>Title</th>
                <th>Content</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            {
              loading ?
                <tbody>
                  <tr> <td colSpan={12} className="text-center">
                    <Spinner />
                  </td>
                  </tr>
                </tbody>
                :
                <tbody>
                  {Array.isArray(state?.data) && state?.data?.length > 0 ? state.data.map((res: any, index: number) => {
                    console.log(res)
                    return (
                      <tr key={res._id}>
                        <td>{index + 1}.</td>
                        <td>{res.type}</td>
                        <td
                          className="word-break"
                          dangerouslySetInnerHTML={{
                            __html:
                              res.description.length > 300
                                ? res.description.slice(0, 300) + "..."
                                : res.description,
                          }}
                        ></td>
                        <td>
                          <div className="text-center">
                            <Link
                              to={`/policies/${res.type}`}
                              className="btn-white btn btn-xs me-2 "
                            >
                              <i className="fas fa-eye me-1"></i>View
                            </Link>
                          </div>
                        </td>
                      </tr>
                    );
                  }) : <tr> <td colSpan={12} className="text-center">
                    Sorry! no data to display
                  </td> </tr>}
                </tbody>
            }

          </table>
        </div>
      </div>
    </div >
  );
};
