import React from "react";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend
} from "recharts";

export default ({ data }:any) => {
    console.log(data[0]?.month);
    console.log(data);
    
    
    return (
        <LineChart
            width={746}
            height={400}
            data={data}
            margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5
            }}
        >
            <CartesianGrid strokeDasharray="3 3" />
         <XAxis dataKey="date" />
            <YAxis/>
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="total_bookings" stroke="#000" />
        </LineChart>
    );
}
