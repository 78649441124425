import Profile from "../../../../assets/images/profile_placeholder.png";
import { Link } from "react-router-dom";
import { GlobalContext } from "../../../../context/Provider";
import axios from 'axios';
import henceforthApi from "../../../../utils/henceforthApi";
import { useContext } from 'react';
import React from "react";
import { ROLES_STAFF_MEMBERS } from "../../../../constants/actionTypes";

const ProfileDropdown = ({ roles }:any) => {

  const { authState, authDispatch } = useContext(GlobalContext);
  if(authState.access_token){henceforthApi.setToken(authState.access_token);}

  return (
    <div className="dropdown profile-element">
      <div>
        <img className="profile-img" src={authState.image ? `${henceforthApi.API_FILE_ROOT_ORIGINAL}${authState.image}` : Profile} alt="" />
      </div>
      <button
        className="dropdown-toggle btn-icon"
        type="button"
        id="dropdownMenu2"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <span className="text-white">
          <span className="text-dark text-xs block text-break text-wrap">{authState.name.charAt(0).toUpperCase() + authState.name.slice(1)}<b className="caret"></b>
          </span>
        </span>
      </button>
      <ul className="dropdown-menu animated fadeInRight m-t-xs" aria-labelledby="dropdownMenu2">
        <li>
          <Link className="dropdown-item" to="/profile">
            Profile
          </Link>
        </li>
        <li>
          {roles.includes(ROLES_STAFF_MEMBERS) || authState.super_admin ?
            <Link className="dropdown-item" to="/staff/1/all">
              Staff Management
            </Link> : ''}
        </li>
      </ul>
    </div>
  );
};

export default ProfileDropdown;
