import Swal from "sweetalert2";
import { Link, useMatch } from "react-router-dom";
import { GlobalContext } from "../context/Provider";
import henceforthApi from "../utils/henceforthApi";
import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import Profile from "../assets/images/profile_placeholder.png";
const ReactQuill =
  typeof window === "object" ? require("react-quill") : () => false;

export default () => {
  const match:any = useMatch("/policies/:type/edit");
  const { authState, authDispatch } = useContext(GlobalContext);
  if(authState.access_token){henceforthApi.setToken(authState.access_token);}
  const [loading, setLoading] = useState(false);
const [file, setFile] = useState(null);
  const { type } = useParams();
const [state, setState] = useState({
    created_at: "",
    image_url: "",
    title: "",
    type: "",
    updated_at: "",
    _id: "",
  });
  const [description, setDesciption] = useState("");
  const [title, setTitle] = useState("");
const handleUpload = async (e:any) => {
    let file=e.target.files[0]
    const apiRes = await (henceforthApi.Auth.uploadImage("file",file)).data;
    setFile(apiRes.data.file_name);
    setState({
      ...state,
      image_url: apiRes.data.file_name,
    });
    const data = {
      type: match.params.type,
      image_url: apiRes.data.file_name,
      description: description,
      language: "ENGLISH",
    };
    henceforthApi.Policies.addEditPolicy(data)
      .then((res:any) => {
        setLoading(false);
        console.log(res);
      })
      .catch((err:any) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleSubmit = (e:any) => {
    console.log(file, "file");
    e.preventDefault();
    const data = {
      type: match.params.type,
      // image_url: state.image_url,
      description: description,
      title: title,
      language: "ENGLISH",
    } as any;
    if (state.image_url) {
      data["image_url"] = state.image_url;
    }

    henceforthApi.Policies.addEditPolicy(data)
      .then((res:any) => {
        setLoading(false);
        console.log(res);
        Swal.fire({
          title: "Content updated",
          text: "Content updated successfully",
          icon: "success",
          confirmButtonText: "OK",
        });

        window.history.back();
      })
      .catch((err:any) => {
        console.log(err);
        setLoading(false);

        Swal.fire({
          title: "Error!",
          text: err.response.body.error_description,
          icon: "error",
          confirmButtonText: "Cancel",
        });
      });
  };
  const initialiseData = async () => {
    try {
      let apiRes = await henceforthApi.Policies.byType(match.params.type);
      console.log(apiRes)
      let obj =
        Array.isArray(apiRes.data) && apiRes.data.length
          ? apiRes.data[0]
          : apiRes.data;
      setState({
        ...state,
        ...obj,
      });
      console.log(state)
      setDesciption(obj.description);
      setTitle(obj.title)
    } catch (error:any) { }
  };

  useEffect(() => {
    initialiseData();
  }, []);

  const onEditorStateChange = (e:any) => {
    // console.log(e);
    setDesciption(e);
  };
  const fileNull = () => {
    console.log("click");
    setFile(null)

  };

  return (
    <div className="animated fadeInRight row">
      <div className="col-md-12">
        <div className="ibox">
          <form onSubmit={(e:any) => handleSubmit(e)}>
            <div className="ibox-content spinerr-wrapper">
              <div className="form-group">
                <label>
                  <h4>Page Title:</h4>
                </label>
                <input
                  value={match.params.type}
                  type="text"
                  placeholder="Enter Page Title"
                  className="form-control text-black"
                  onChange={(e:any) => setTitle(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>
                  <h4>Description:</h4>
                </label>
                <div
                  style={{
                    border: "1px solid rgba(0,0,0,.2)",
                    paddingBottom: "20px",
                    background: "white",
                  }}
                >
                  <ReactQuill
                    theme="snow"
                    onChange={(e:any) => onEditorStateChange(e)}
                    placeholder={`Write your content`}
                    className="text-black"
                    value={description}
                  />
                </div>
              </div>
              <div className="form-group  spinner-inner">
              </div>
              <div className="form-group text-end">
                <button
                  type="button"
                  className="btn btn-white btn-sm"
                  onClick={() => window.history.back()}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary-button btn-sm ms-2"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
