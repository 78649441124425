import CountCard from "../components/countCard";
import profile_placeholder from "../assets/images/profile_placeholder.png";
import { Link, useLocation } from "react-router-dom";
import henceforthApi from "../utils/henceforthApi";
import { GlobalContext } from "../context/Provider";
import React, { useEffect, useContext } from "react";
import staticDataDashboardSuccess from "../context/actions/auth/staticDataDashboardSuccess";
import NFTPriceBarChar from "../components/timeOrderGraph/NFTPriceBarChar";
import moment from "moment";
export default () => {
  const location = useLocation();
  const searchParam = new URLSearchParams(location.search);
  const { authState, staticDataState, staticDataDispatch, handleError } = useContext(GlobalContext);
  if (authState.access_token) { henceforthApi.setToken(authState.access_token); }

  const dashboard = staticDataState.dashboard;
  const dashBoard = async () => {
    try {
      let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const dbData = await henceforthApi.Dashboard.getPagnation(
        searchParam.get("type"),
      );
      console.log(dbData)
      staticDataDashboardSuccess(dbData.data)(staticDataDispatch);
    } catch (error) {
      handleError(error)
    }

  }
  useEffect(() => {
    dashBoard()
  }, [searchParam.get("type")]);
  console.log(staticDataState.dashboard);

  useEffect(() => {
  }, []);
  const FILE_EXT = "video/mp4";
  const CONTENT_TYPE = "AUDIO";
  console.log(dashboard?.total_bookings)

  return (
    <div className="animated fadeInRight">
      <div className="row">
        <Link to="/users/1" className="col-md-3">
          <CountCard
            title="Users"
            count={dashboard?.total_users}
            currency=""
            icon="fas fa-users"
          />
        </Link>
        <Link to="/booked-rides/1" className="col-md-3">
          <CountCard
            title="Bookings"
            count={dashboard?.total_bookings}
            currency=""
            icon="fas fa-folder"
          />
        </Link>

        <Link to="/transactions/1" className="col-md-3">
          <CountCard
            title="Total No. of Transactions"
            count={parseFloat(Number(dashboard?.total_transactions ? dashboard?.total_transactions : 0).toFixed(10))}
            currency=""
            icon="fas fa-folder"
          />
        </Link>
        <Link to="/contact-us/1" className="col-md-3">
          <CountCard
            title="Contact Us"
            count={parseFloat(Number(dashboard?.total_contacts ? dashboard?.total_contacts : 0).toFixed(10))}
            currency=""
            icon="fas fa-folder"
          />
        </Link>
        <Link to="/users/1?document_verified=0" className="col-md-3">
          <CountCard
            title="User Doc Verification"
            count={parseFloat(Number(dashboard?.total_docs_verified ? dashboard?.total_docs_verified : 0).toFixed(10))}
            currency=""
            icon="fas fa-folder"
          />
        </Link>

      </div>

      <NFTPriceBarChar
      // searchParam={searchParam}
      // title={"Time vs Sales"}
      // {...dashboard.graph_data}
      />
      <div className="row">
        <div className="col-md-6">
          <div className="ibox">
            <div className="ibox-title">
              <h5>Recent Booked Rides</h5>
            </div>
            <div className="ibox-content">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Sr No.</th>
                      <th>Name</th>
                      <th>Pick up Location</th>
                      <th>Drop off Location</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dashboard?.recent_bookings?.slice(0, 10)?.map((res: any, index: number) => {
                      return (
                        <tr key={res._id}>
                          <td>{index + 1}.</td>
                          <td>
                            <div className="text-nowrap">
                              {res.content_type === CONTENT_TYPE ? (
                                <img
                                  src={`https://ipfs.infura.io/ipfs/${res?.cover_img}`}
                                  className="me-2 imageSize"
                                  alt=""
                                ></img>
                              ) : (
                                <img
                                  src={res?.user_id?.profile_pic !== null && res?.user_id?.profile_pic !== 'null' ? henceforthApi.FILES.imageOriginal(res?.user_id?.profile_pic) : profile_placeholder}
                                  className="me-2 imageSize"
                                  alt=""
                                ></img>
                              )}
                              {/* res?.profile_pic !== 'null' && res?.profile_pic !== null ? henceforthApi.FILES.imageOriginal(res?.profile_pic) :profile_placeholder */}

                              {/* <img src={`${henceforthApi.API_FILE_ROOT_SMALL}${res.cover_img}`}></img> */}

                              {res?.user_id?.name ? res?.user_id?.name : "Not available"}
                            </div>
                          </td>
                          <td title={res?.ride_id?.address_from}>
                            {res?.ride_id?.address_from.length > 15 ? res.ride_id?.address_from.slice(0, 15) : res.ride_id?.address_from || "not available"}
                          </td>
                          <td title={res?.ride_id?.address_to}>
                            {res?.ride_id?.address_to.length > 15 ? res.ride_id?.address_to.slice(0, 15) : res.ride_id?.address_to || "not available"}
                          </td>

                          <td>
                            <Link
                              to={`/booking-details/${res._id}`}
                              className="btn btn-xs btn-white"
                            >
                              <i className="fas fa-eye me-1"></i>View
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {dashboard?.total_bookings > 10 ?
                  <div className="view-more mb-2">
                    <div className="d-flex align-items-center justify-content-center">
                      <Link
                        to="/booked-rides/1"
                        className="btn btn-xs btn-white me-2"
                      >
                        View More
                      </Link>
                    </div>
                  </div> : dashboard?.total_bookings == 0 ? "Sorry ! no data display " : ""

                }
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="ibox">
            <div className="ibox-title">
              <h5>Recent Users</h5>
            </div>
            <div className="ibox-content">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Sr No.</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dashboard?.recent_users?.map((res: any, index: number) => {
                      return (
                        <tr key={res._id}>
                          <td>{index + 1}.</td>
                          <td>
                            <div className="text-nowrap">
                              <img
                                src={
                                  res?.profile_pic !== null && res?.profile_pic !== 'null'
                                    ? henceforthApi.FILES.imageOriginal(res?.profile_pic)
                                    : profile_placeholder
                                }
                                className="me-2 imageSize"
                                alt=""
                              />
                              {res.name ? res.name : "Not available"}
                            </div>
                          </td>
                          <td> {res.email ? res.email : res.social_token}</td>
                          <td>
                            <Link
                              to={`/user/${res._id}`}
                              className="btn btn-xs btn-white"
                            >
                              <i className="fas fa-eye me-1"></i>
                              View
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {dashboard?.total_users > 10 ?
                  <div className="view-more mb-2">
                    <div className="d-flex align-items-center justify-content-center">
                      <Link to="/users/1" className="btn btn-xs btn-white me-2">
                        View More
                      </Link>
                    </div>
                  </div> : ""}
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  );
};
