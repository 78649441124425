import { LOGIN_SUCCESS, LOGOUT_SUCCESS, AUTH_AGE_VERIFY, SIGNUP_SUCCESS, INITIALISE_PROFILE, SOCIAL_TYPE_PROVIDER, CHAT_STATUS } from "../../constants/actionTypes";

const auth = (state:any, { type, payload }:any) => {
    switch (type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                ...payload,
                isLogin: true,
                isSignUp: false,
            }
        case LOGOUT_SUCCESS:
            return {
                ...state,
                access_token: "",
                email: null,
                email_verified: null,
                user: null,
                user_name: null,
                account_status: null,
                account_type: null,
                device_type: null,
                token_gen_at: null,
                type: null,
                _id: null,
                isLogin: false,
                isSignUp: false,
            }
        case AUTH_AGE_VERIFY:
            return {
                ...state,
                ...payload,
            }
        case INITIALISE_PROFILE:
            return {
                ...state,
                ...payload,
            }
        case SIGNUP_SUCCESS:
            return {
                ...state,
                ...payload,
                isLogin: false,
                isSignUp: true,
            }
        case SOCIAL_TYPE_PROVIDER:
            return {
                ...state,
                ...payload,
            }
        case CHAT_STATUS:
            return {
                ...state,
                ...payload,
            }
        default: {
            return {
                ...state,
            }
        }
    }
}

export default auth;