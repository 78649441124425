import React,{ useContext } from "react";
import { Link } from "react-router-dom";
import { GlobalContext } from "../../../context/Provider";
const Header = (props:any) => {
  const { logOutNow } = useContext(GlobalContext);
  return (
    <div className="row border-bottom ">
      <nav
        className="navbar navbar-static-top white-bg  "
        role="navigation"
        style={{ marginBottom: "0" }}
      >
        <div className="navbar-header">
          <Link
            className="navbar-minimalize minimalize-styl-2 btn  btn-primary-button"
            to="#"
            onClick={props.toggle}
          >
            <i className="fa fa-bars"></i>{" "}
          </Link>
        </div>
        <ul className="nav navbar-top-links navbar-right align-items-center">
          <li>
            <a>
              <button className="btn  btn-primary-button" onClick={() => logOutNow()}>
                <i className="fa fa-sign-out"></i> Log out
              </button>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Header;
