import React, { useCallback, useMemo } from "react";
import { Link, useLocation, useMatch, useNavigate } from "react-router-dom";
import profile_placeholder from "../assets/images/profile_placeholder.png";
import { useState, useEffect, useContext } from "react";
import henceforthApi from "../utils/henceforthApi";
import { GlobalContext } from "../context/Provider";
import Swal from "sweetalert2";
import moment from "moment";
import { Icons, toast } from "react-toastify";
import RideCreated from "./rideCreated";
import RideBooked from "./rideBooked";
import UserVehicles from "./userVehicles";
import MaskImage from "../components/common/MaskImage";
type userDetails = {
  account_status: string,
  account_type: string,
  admin_verified: boolean,
  age_verified: boolean,
  bio: string,
  image: string
  cover_img: string,
  created_at: string,
  email: string,
  email_verified: boolean,
  name: string,
  is_blocked: boolean,
  is_deleted: boolean,
  last_name: string,
  last_seen?: null,
  profile_pic: string,
  social_token: string,
  licence_id: string,
  phone_no: string,
  gender: string,
  front_doc: any,
  document_status: string
  social_type: string,
  total_coins: number,
  updated_at: null,
  date_of_birth: string
  user_name: string,
  user_status: number,
  _id: string,
}
export default () => {
  const match: any = useMatch("/user/:id");
  let sta = +1
  const navigate = useNavigate();
  const location = useLocation()
  console.log(location)
  const { authState, handleError } = useContext(GlobalContext);

  const [userDetails, setUserDetails] = useState({
    account_status: "",
    account_type: "",
    admin_verified: false,
    age_verified: false,
    bio: "",
    cover_img: "",
    created_at: "",
    gender: "",
    email: "",
    phone_no: '',
    document_status: "",
    licence_id: '',
    email_verified: false,
    name: "",
    is_blocked: false,
    is_deleted: false,
    last_name: "",
    last_seen: null,
    front_doc: "",
    date_of_birth: "",
    profile_pic: "",
    social_token: "",
    social_type: "",
    total_coins: 0,
    updated_at: null,
    user_name: "",
    user_status: 0,
    _id: "",
  } as userDetails);
  if (authState.access_token) { henceforthApi.setToken(authState.access_token); }

  const [manageUsers, setManageUsers] = useState({
    type: "",
    _id: "",
    is_blocked: false,
    is_deleted: false,
    admin_verified: false,
    language: "ENGLISH",
    account_status: "",
  });

  const getUserDetails = async () => {
    try {
      const UserDetail = await henceforthApi.User.getUserDetails(match.params.id);
      console.log("user details are", UserDetail);
      setUserDetails(UserDetail.data);
    } catch (error) {
      handleError(error)
    }
  };

  // const imageSize = () => {
  //   let img = document.getElementById("zoom");
  //   img.style.transform = "scale(10)"


  //   //  img.style.padding=  "10px"
  // }
  const handleActivate = async () => {
    let data = {
      type: "ACTIVATE/DEACTIVATE",
      _id: userDetails._id,
      is_blocked: userDetails.is_blocked,
      is_deleted: userDetails.is_deleted,
      language: "ENGLISH",
      account_status:
        userDetails.account_status == "ACTIVATED" ? `DEACTIVATED` : `ACTIVATED`,
    } as any;

    try {
      const manage_users = await henceforthApi.User.manageUsers(data);
      console.log("mmanage users", manage_users);
      setManageUsers(data);
      {
        userDetails.account_status == "DEACTIVATED"
          ? Swal.fire({
            title: "Activated",
            text: "Account Activated Successfully",
            icon: "success",
            confirmButtonText: "OK",
          })
          : Swal.fire({
            title: "Deactivated",
            text: "Account Deactivated Successfully",
            icon: "success",
            confirmButtonText: "OK",
          });
      }
    } catch (error: any) {
      console.log(error, "error is ");
    }
  };
  const verify = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Verify it!",
    }).then(async (result: any) => {
      if (result.isConfirmed) {
        try {
          let apiRes = await henceforthApi.User.verifyUser(
            match?.params.id,
            1
          ).then((res: any) => {
            Swal.fire({
              title: "Verification Successful",
              // text: "Verified  successful",
              icon: "success",
              confirmButtonText: "OK",
            })
            getUserDetails()
          }).catch((err: any) => {
            console.log("Verify failed", err);
            Swal.fire({
              title: "Verified Failed",
              text: err.response.body.error_description,
              icon: "error",
              confirmButtonText: "Cancel",
            });
          });
          console.log("verify", apiRes)
        }
        catch { }
      }
    })
  }
  const Reject = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Reject it!",
    }).then(async (result: any) => {
      if (result.isConfirmed) {
        try {
          let apiRes = await henceforthApi.User.rejectUser(match?.params.id, 0).then((res: any) => {
            Swal.fire({
              title: "Reject Successful",
              // text: "Rejected  successfully",
              icon: "success",
              confirmButtonText: "OK",
            })
            getUserDetails()
          }).catch((err: any) => {
            console.log("Reject failed", err);
            Swal.fire({
              title: "Reject Failed",
              text: err.response.body.error_description,
              icon: "error",
              confirmButtonText: "Cancel",
            });
          });
          console.log("verify", apiRes)
        }
        catch (err: any) { console.log(err); }
      }
    })
  }
  const handleBlocking = async () => {
    let data = {
      type: "BLOCK/DELETE",
      _id: userDetails._id,
      is_blocked: !Boolean(userDetails.is_blocked),
      is_deleted: userDetails.is_deleted,
      language: "ENGLISH",
      account_status: userDetails.account_status,
    } as any;
    try {
      const manage_users = await henceforthApi.User.manageUsers(data);
      console.log("mmanage users", manage_users);
      setManageUsers(data);
      {
        !userDetails.is_blocked
          ? Swal.fire({
            title: "User Blocked",
            text: "User Blocked  successfully",
            icon: "success",
            confirmButtonText: "OK",
          })
          : Swal.fire({
            title: "User Unblocked",
            text: "User Unblocked  successfully",
            icon: "success",
            confirmButtonText: "OK",
          });
      }
    } catch (error: any) {
      console.log(error, "error is ");
      Swal.fire({
        title: "Error!",
        text: error.response.body.error_description,
        icon: "error",
        confirmButtonText: "Cancel",
      });
    }
  };


  const copyText = (id: string) => {
    navigator?.clipboard?.writeText(id)
    toast.success("id copied")
  }
  const handleDelete = async (creatorId: any) => {
    let data = {
      type: "BLOCK/DELETE",
      _id: userDetails._id,
      is_blocked: userDetails.is_blocked,
      is_deleted: true,
      language: "ENGLISH",
      account_status: userDetails.account_status,
    };

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result: any) => {
      if (result.isConfirmed) {
        try {
          henceforthApi.User.manageUsers(data)
            .then((res: any) => {
              console.log(res, "response");
              Swal.fire({
                title: "User Deleted",
                text: res.data.message,
                icon: "success",
                confirmButtonText: "OK",
              });
              window.history.back();
            }).catch((err: any) => {
              console.log(err, "ERROR");

              Swal.fire({
                title: "Error!",
                text: err,
                icon: "error",
                confirmButtonText: "Cancel",
              });
            });
        } catch (error: any) {

        }
      }
    });

  };
  useEffect(() => {
    getUserDetails();
  }, [manageUsers]);

  useEffect(() => {
    // scrollToTop();
  }, []);

  console.log("userDetails", userDetails);


  return (
    <>
      <div className="row animated fadeInRight">
        <div className="col-md-6">
          <div className="ibox">
            <div className="ibox-title">
              <h5>User Details</h5>
            </div>
            <div className="ibox-content">
              <div className="upload-image m-auto position-relative">
                <div className="text-center">
                  <img
                    src={
                      userDetails?.profile_pic !== null && userDetails?.profile_pic !== 'null'
                        ? henceforthApi.FILES.imageOriginal(userDetails?.profile_pic)
                        : profile_placeholder
                    }
                    alt="ProfileImage"
                    className="img-fluid rounded-circle"
                  />
                  {/* {ImageModal(userDetails?.profile_pic)} */}
                  <MaskImage image={userDetails?.profile_pic} key={userDetails?.profile_pic} />
                </div>
              </div>
              <div className="mt-4">
                <p className="text-black mb-1 user-text">
                  <b>Name :</b>
                  <span className="text-muted-userD ms-1">
                    {userDetails?.name
                      ? ` ${userDetails?.name} `
                      : `Not Available`}
                  </span>
                </p>
                {/* <p className="text-black mb-1 user-text">
                  <b>Licence Id :</b>
                  <span className="text-muted-userD ms-1">
                    {userDetails?.licence_id ? `${userDetails.licence_id}` : "Not available"}
                  </span>
                </p> */}
                <p className="text-black mb-1 user-text">
                  <b>Phone no :</b>
                  <span className="text-muted-userD ms-1">
                    {userDetails?.phone_no}
                  </span>
                </p>
                <p className="text-black mb-1 user-text">
                  <b>Gender :</b>
                  <span className="text-muted-userD ms-1">
                    {userDetails?.gender ? `${userDetails?.gender}` : "not available"}
                  </span>
                </p>
                <p className="text-black mb-1 user-text">
                  <b>Date of Birth :</b>
                  <span className="text-muted-userD ms-1">
                    {userDetails?.date_of_birth ? `${userDetails?.date_of_birth.slice(0, 10)}` : "not available"}
                  </span>
                </p>

                <p className="text-black mb-1 user-text">
                  <b>Date Activate :</b>
                  <span className="text-muted-userD ms-1">
                    {moment(Number(userDetails?.created_at)).format("YYYY-MM-DD")}
                  </span>
                </p>
                <p className="text-black mb-1 user-text">
                  <b>Id :</b>
                  <span className="text-muted-userD ms-1">
                    {userDetails?._id ? `${userDetails?._id}` : "not available"}<i role="button" className="bi bi-clipboard ms-2" onClick={() => { navigator?.clipboard?.writeText(userDetails?._id); toast.success("Id Copied") }} ></i>
                  </span>
                </p>
                <hr />
                <p>
                  {userDetails?.email ? <i className="fas fa-envelope me-2"><span className="text-muted mx-1"> {userDetails?.email}</span></i> : <i className="fas fa-wallet me-2"><span className="text-muted mx-1">{userDetails?.email?.slice(0, 10) + "..."}</span> </i>}
                </p>
                <hr />
                <div className="mt-4">
                  <button
                    type="button"
                    onClick={handleBlocking}
                    className="btn-default btn btn-sm me-2">
                    <i
                      className={
                        userDetails?.is_blocked == false
                          ? `fas fa-unlock me-2`
                          : `fas fa-lock me-2`
                      }
                    ></i>
                    {userDetails?.is_blocked == false ? `Block` : `Unblock`}
                  </button>

                </div>
                <div className="mt-3">
                  <button
                    type="button"
                    onClick={handleActivate}
                    className="btn-default btn btn-sm me-2"
                  >
                    <i
                      className={
                        userDetails?.account_status == "ACTIVATED"
                          ? `fas fa-unlock me-2`
                          : `fas fa-lock me-2`
                      }
                    ></i>
                    {userDetails?.account_status == "ACTIVATED"
                      ? `Deactivate`
                      : `Activate`}
                  </button>
                  <button
                    onClick={handleDelete}
                    disabled={userDetails?.is_deleted ? true : false}
                    className="btn-outline-primary btn btn-sm me-2"
                  >
                    <i
                      className={
                        userDetails?.is_deleted
                          ? `fa fa-ban me-2`
                          : "fas fa-trash-alt me-2"
                      }
                    ></i>
                    {userDetails?.is_deleted ? `Deleted` : `Delete`}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Licence  */}
        <div className="col-md-6">
          <div className="ibox">
            <div className="ibox-title d-flex justify-content-between">
              <h5>Govt ID </h5>
              <div className="d-flex">
                {/* {userDetails?.document_status=="PENDING" ? */}
                <>
                  {userDetails?.document_status == "ACCEPTED" ? "" :
                    <div>
                      <button className="btn btn-danger me-4" disabled={userDetails?.front_doc == null || userDetails?.document_status == "REJECTED" ? true : false} onClick={Reject}>Reject</button></div>}
                  {userDetails?.document_status == "REJECTED" ? "" :
                    <div>
                      <button className="btn btn-color" disabled={userDetails?.front_doc == null || userDetails?.document_status == "ACCEPTED" ? true : false} onClick={verify}>{userDetails?.document_status == "ACCEPTED" ? "Verified" : "Verify"}</button></div>}</>
                {/* :""} */}
              </div>
            </div>
            <div className="ibox-content">
              <div className="user-details-image position-relative">
                <div className="text-center">
                  <img
                    src={
                      userDetails?.front_doc !== null && userDetails?.front_doc !== 'null'
                        ? henceforthApi.FILES.imageOriginal(userDetails?.front_doc)
                        : profile_placeholder
                    }
                    alt="ProfileImage"
                    className="img-fluid user-details-image"
                  />
                    <MaskImage image={userDetails?.front_doc} key={userDetails?.front_doc} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-md-6 animated fadeInRight">
        <div className="ibox">
          Verify Licence id
          </div>
        </div> */}
      </div>
      <div className="row">
        <RideCreated _id={match?.params.id} />
      </div>
      <div className="row">  <RideBooked _id={match?.params.id} /> </div>
      {/* Car Listing  */}
      <div className="row"> <UserVehicles _id={match?.params.id} /> </div>
   
    </>
  );
};
