import { Link } from "react-router-dom";
import React,{ useState } from "react";

const EmailTemplates = () => {
  const [state, setState] = useState({
    data: [
      {
        id: 1,
        title: "template 1",
        subject: "templates subject",
        body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
      },
      {
        id: 2,
        title: "template 2",
        subject: "templates subject",
        body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
      },
      {
        id: 3,
        title: "template 3",
        subject: "templates subject",
        body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
      },
    ],
  });
  return (
    <>
      <div className="ibox animated fadeInRight">
        <div className="back-btn">
          <Link to="/add-template" className="btn btn-primary-button">
            Add Templates
          </Link>
        </div>
        <div className="ibox-title">
          <h5>Email Templates</h5>
        </div>
        <div className="ibox-content">
          <div className="input-group form-group">
            <input
              placeholder="Search by Title, Content"
              type="text"
              className="form-control form-control-sm"
            />{" "}
            <span className="input-group-append">
              <button type="button" className="btn btn-sm btn-primary-button h-100">
                Go!
              </button>
            </span>
          </div>
          <div className="table-responsive">
            <table className="table table-striped email-template">
              <thead>
                <tr>
                  <th>Sr no</th>
                  <th>Title</th>
                  <th>Subject</th>
                  <th>Body</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {state.data.map((res:any) => {
                  return (
                    <tr>
                      <td>{res.id}</td>
                      <td>{res.title}</td>
                      <td>{res.subject}</td>
                      <td>
                        <p className="truncate">{res.body}</p>
                      </td>
                      <td>
                        {" "}
                        <div className="btn-group">
                          <Link
                            to="/edit-template"
                            className="btn-white btn btn-xs me-2"
                          >
                            <i className="fas fa-edit me-1"></i>edit
                          </Link>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {/* <Pagination /> */}
        </div>
      </div>
    </>
  );
};

export default EmailTemplates;
