import React from "react";
import { Link } from "react-router-dom";

const EditLanguage = () => {
  return (
    <div className="row animated fadeInRight">
      <div className="col-md-12">
        <div className="ibox">
          <div className="ibox-title p-3">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4>Edit Language</h4>
              </div>
              <div>
                <Link
                  to="/language"
                  className="btn-primary-button btn mr-2  back-btn"
                >
                  <i className="fa fa-arrow-left me-1"></i>Back
                </Link>
              </div>
            </div>
          </div>
          <div className="ibox-content">
            <div className="row">
              <div className="form-group col-md-6">
                <label className="form-label">English String</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter English String"
                />
              </div>
              <div className="form-group col-md-6">
                <label className="form-label">Arabic String</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Arabic String"
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-4 col-sm-offset-2">
                <button className="btn btn-white btn-sm" type="submit">
                  Cancel
                </button>
                <button
                  className="btn btn-primary-button btn-sm ms-2"
                  type="submit"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditLanguage;
