import _superagent from "superagent";
// import superagentPromise from "superagent-promise";
const superagentPromise = require("superagent-promise")
const superagent = superagentPromise(_superagent, global.Promise);

const API_ROOT = `https://${(window.origin.includes('staging') || window.origin.includes('localhost')) ? `staging.pudoapp.com:3001/` : window.origin.includes('production') ? `pudoapp.com:3000/` : `pudoapp.net:3000/`}`;
// const API_ROOT = 'https://api.pudoapp.com:3000/'
// const API_FILE_ROOT_ORIGINAL = `https://pudostaging.s3.us-east-2.amazonaws.com/pudostaging/original/`

const BUCKET_ROOT = (window.origin.includes('staging') || window.origin.includes('localhost')) ? `https://pudostaging.s3.us-east-2.amazonaws.com/pudostaging/` : `https://pudolive.s3.us-east-2.amazonaws.com/pudolive/`;

// const AUDIO_BUCKET_ROOT = "https://pudostaging.s3.us-east-2.amazonaws.com/pudostaging/"

const API_FILE_ROOT_MEDIUM = `${BUCKET_ROOT}images/medium/`;
const API_FILE_ROOT_ORIGINAL = `${BUCKET_ROOT}original/`;
const API_FILE_ROOT_SMALL = `${BUCKET_ROOT}images/small/`;
const API_FILE_ROOT_AUDIO = `${BUCKET_ROOT}audio/`;
const API_FILE_ROOT_VIDEO = `${BUCKET_ROOT}video/`;
const API_FILE_ROOT_DOCUMENTS = `${BUCKET_ROOT}documents/`;

// const encode = encodeURIComponent;
const responseBody = (res: any) => res.body;

let token = null as null | string;
const tokenPlugin = (req: any) => {
  if (token) {
    req.set("token", `${token}`);
  }
};

const requests = {
  del: (url: any) =>
    superagent.del(`${API_ROOT}${url}`).use(tokenPlugin).then(responseBody),
  get: (url: any) =>
    superagent.get(`${API_ROOT}${url}`).use(tokenPlugin).then(responseBody),
  put: (url: any, body: any) =>
    superagent
      .put(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody),
  patch: (url: any, body: any) =>
    superagent
      .patch(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody),
  post: (url: any, body: any) =>
    superagent
      .post(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody),
  file: (url: any, key: any, file: any) =>
    superagent.post(`${API_ROOT}${url}`)
      .attach(key, file)
      .use(tokenPlugin)
      .then(responseBody),
  post2: (url: any) =>
    superagent
      .post(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .then(responseBody),

};

const Auth = {
  login: (info: any) => requests.post("Admin/login", info),
  signup: (info: any) => requests.post("signup", info),
  forgotPassword: (info: any) => requests.post("password/forgot", info),
  checkOtp: (info: any) => requests.post("check-email-otp", info),
  resetPassword: (info: any) => requests.post("reset/password", info),
  changePassword: (info: any) => requests.put("Admin/change_password", info),
  uploadImage: (key: string, file: any) => requests.file('Upload/do_spaces_file_upload', key, file)
};

const Dashboard = {
  getPagnation: (type: any) =>
    requests.get(
      `Admin/dashboard?type=${String(
        type ? type : "MONTHLY"
      ).toUpperCase()}`
    ),
  getTradingPriceById: (graph_type: string | null) =>
    requests.get(`Admin/graph/booking?type=${String(graph_type ? graph_type : "DAILY")}&language=ENGLISH`),
};

const Facility = {
  getApprovalPagnation: (page: number, limit: number, filter: string) =>
    requests.get(
      `admin/facility/approvals?page=${page}&limit=${limit ? limit : 10}${filter ? filter : ""
      }`
    ),
  getPagnation: (page: number, limit: number, filter: string) =>
    requests.get(
      `admin/facilities?page=${page}&limit=${limit ? limit : 10}${filter ? filter : ""
      }`
    ),
  approvalAccept: (facility_id: string) =>
    requests.put("admin/facility/approval", {
      facility_id: facility_id,
      status: 1,
    }),
  approvalDecline: (facility_id: string) =>
    requests.put("admin/facility/approval", {
      facility_id: facility_id,
      status: 4,
    }),
  updateFee: (item: any) => requests.put("admin/facility/fees", item),
};

const Transaction = {
  getPagnation: (page: number, limit: number, filter: string) =>
    requests.get(
      `admin/transactions?page=${page}&limit=${limit ? limit : 10}${filter ? filter : ""
      }`
    ),
  export: (start_date: string, end_date: string) =>
    requests.get(
      `Admin/transactions/export?start_date=${start_date}&end_date=${end_date}
      }`
    ),
};

const PromoCode = {
  getPagnation: (page: number, limit: number, filter: string) =>
    requests.get(
      `admin/transactions?page=${page}&limit=${limit ? limit : 10}${filter ? filter : ""
      }`
    ),
  createEndUser: (item: any) => requests.post("admin/promo_code", item),
};

const User = {
  export: (start_date: string, end_date: string) =>
    requests.get(
      `Admin/users/listing?start_date=${start_date}&end_date=${end_date}`
    ),

  getListing: (q: string, pagination: number, limit: number) => {
    return requests.get(
      `Admin/users/listing?language=ENGLISH${q ? `&${q}` : ""}&pagination=${pagination}&limit=${limit}`
    );
  },
  getTips: (_id: string, pagination: number) =>
    requests.get(
      `Admin/users/tip_history?language=ENGLISH&_id=${_id}&pagination=${pagination}&limit=10`
    ),
  verifyUser: (_id: string, status: number) => requests.post2(`Admin/users/verify_document?_id=${_id}&status=${status}`),
  rejectUser: (_id: string, status: number) => requests.post2(`Admin/users/verify_document?_id=${_id}&status=${status}`),
  getUserDetails: (_id: string) => requests.get(`Admin/users/details?_id=${_id}&language=ENGLISH`),

  pagination: (_id: string, pagination: number, limit: number) => requests.get(`Admin/users/bookings?_id=${_id}&pagination=${pagination}&limit=${limit}`),
  manageUsers: (values: any) =>
    requests.put("Admin/users/manage_users", {
      type: values.type,
      _id: values._id,
      is_blocked: values.is_blocked,
      is_deleted: values.is_deleted,
      account_status: values.account_status,
      language: values.language,
      admin_verified: values.admin_verified ? values.admin_verified : false,
    }),

  sendCredit: (info: any) => requests.put("admin/credit", info),
  delete: (info: any) => requests.del(`admin/user/${info}`),
  getVaultNfts: (_id: string, pagination: number) =>
    requests.get(
      `Admin/users/nfts?language=ENGLISH&type=VAULT&user_id=${_id}&pagination=${pagination}&limit=10`
    ),
  // getResaleNfts: (_id, pagination) =>
  //   requests.get(
  //     `Admin/users/nfts?language=ENGLISH&type=ON_SALE&user_id=${_id}&pagination=${pagination}&limit=10`
  //   ),
  getOwnNfts: (_id: string, pagination: number) =>
    requests.get(
      `Admin/users/nfts?language=ENGLISH&type=OWNED&user_id=${_id}&pagination=${pagination}&limit=10`
    ),
  getSoldNfts: (_id: string, pagination: number) =>
    requests.get(
      `Admin/users/nfts?language=ENGLISH&type=SOLD&user_id=${_id}&pagination=${pagination}&limit=10`
    ),
  getCreatedNfts: (_id: string, pagination: number) =>
    requests.get(
      `Admin/users/nfts?language=ENGLISH&type=CREATED&user_id=${_id}&pagination=${pagination}&limit=10`
    ),
  getBidNfts: (_id: string, pagination: number) =>
    requests.get(
      `Admin/users/nfts?language=ENGLISH&type=BIDS&user_id=${_id}&pagination=${pagination}&limit=10`
    ),
  getFavNfts: (_id: string, pagination: number) =>
    requests.get(
      `Admin/users/nfts?language=ENGLISH&type=FAVOURITE&user_id=${_id}&pagination=${pagination}&limit=10`
    ),
  getActivityNfts: (_id: string, pagination: number) =>
    requests.get(
      `Admin/users/nfts?language=ENGLISH&type=ACTIVITY&user_id=${_id}&pagination=${pagination}&limit=10`
    ),
  getCollectionsNfts: (_id: string, pagination: number) =>
    requests.get(
      `Admin/users/nfts?language=ENGLISH&type=COLLECTION&user_id=${_id}&pagination=${pagination}&limit=10`
    ),
  getDividendsNfts: (_id: string, pagination: number) =>
    requests.get(
      `Admin/users/nfts?language=ENGLISH&type=DIVIDENDS&user_id=${_id}&pagination=${pagination}&limit=10`
    ),
};

const Booking = {
  list: (pagination: number, limit: number, search: string,) => {
    requests.get(`Admin/bookings?pagination=${pagination}&limit=${limit}${search ? `&search=${search}` : ''}`)
  },

  getBookingDetails: (_id: string) => requests.get(`Admin/bookings/${_id}&language=ENGLISH`),
}

const CarListing = {
  pagination: (_id: string) => requests.get(`Admin/user_vehicles?driver_id=${_id}`),
}
const Coupons = {
  getCoupons: (info: any) => requests.post(`Admin/coupons`, info),
  getCouponsList: (q: string, limit: number, pagination: number) => requests.get(`Admin/coupons?language=ENGLISH${q ? `&${q}` : ""}&limit=${limit}&pagination=${pagination}`),
  delCoupons: (id: string) => requests.del(`Admin/coupon/${id}`)
}

// const Creator = {
//   export: (start_date, end_date, timezone) =>
//     requests.get(
//       `Admin/creator?language=ENGLISH&start_date=${start_date}&end_date=${end_date}&timezone=${timezone}`
//     ),
//   reported: (_id, pagination) =>
//     requests.get(
//       `Admin/creator/reported?language=ENGLISH&_id=${_id}&pagination=${pagination}&limit=10`
//     ),
//   getListing: (search, pagination) =>
//     requests.get(
//       `Admin/nfts?language=ENGLISH${search ? `&search=${search}` : ""
//       }&pagination=${pagination}&limit=10`
//     ),
//   getCreatorDetails: (_id) =>
//     requests.get(`Admin/nfts/details?${_id}&language=ENGLISH`),
//   getTips: (_id, pagination) =>
//     requests.get(
//       `Admin/users/tip_history?language=ENGLISH&_id=${_id}&pagination=${pagination}&limit=10`
//     ),
//   // getPhotos: (creatorId) =>
//   //   requests.get(`Admin/nfts/creator/${creatorId}?language=ENGLISH`),
//   getCreatedNfts: (_id, pagination) =>
//     requests.get(
//       `Admin/nfts/creator?language=ENGLISH&type=CREATED&created_by=${_id}&pagination=${pagination}&limit=10`
//     ),
//   getOwnNfts: (_id, pagination) =>
//     requests.get(
//       `Admin/nfts/creator?language=ENGLISH&type=OWNED&created_by=${_id}&pagination=${pagination}&limit=10`
//     ),
//   getSoldNfts: (_id, pagination) =>
//     requests.get(
//       `Admin/users/nfts?language=ENGLISH&type=SOLD&created_by=${_id}&pagination=${pagination}&limit=10`
//     ),
//   verifyProfile: (_id, admin_verified) =>
//     requests.put("Admin/creator", {
//       _id,
//       type: "VERIFY/UNVERIFY",
//       admin_verified: !admin_verified,
//       language: "ENGLISH",
//     }),
//   manageCreators: (values) =>
//     requests.put("Admin/creator", {
//       type: values.type,
//       _id: values._id,
//       is_blocked: values.is_blocked,
//       is_deleted: values.is_deleted,
//       admin_verified: values.admin_verified,
//       account_status: values.account_status,
//       language: values.language,
//     }),

//   getMediaList: (_id, graph_type, timezone) =>
//     // requests.get(`Admin/creator/${creatorId}?language=ENGLISH`),
//     requests.get(
//       `Admin/nfts/details?language=ENGLISH&_id=${_id}&graph_type=YEARLY&timezone=${timezone}`
//     ),

//   sendCredit: (info) => requests.put("admin/credit", info),
//   delete: (info) => requests.del("admin/user", info),
// };

const BookingListing = {
  getPagnation: (page: number, q: string, limit: number,) =>
    requests.get(
      `Admin/bookings?pagination=${page}${q ? `&${q}` : ""}&limit=${limit}`),
  getBookingDetails: (_id: string) => requests.get(`Admin/bookings/${_id}`),
  export: (start_date: string, end_date: string) =>
    requests.get(
      `Admin/bookings?start_date=${start_date}&end_date=${end_date}`
    ),
};
const Rides = {
  getListing: (search: string, q: string, pagination: number, limit: number) => {
    return requests.get(
      `Admin/rides?language=ENGLISH${search ? `&${search}` : ""}&pagination=${pagination}&limit=${limit}`
    );
  },
  export: (start_date: string, end_date: string) =>
    requests.get(
      `Admin/rides?start_date=${start_date}&end_date=${end_date}`
    ),
}

const Report = {
  ride: (search: string, q: string, limit: number, pagination: number) => {
    return requests.get(
      `Admin/reported/users?language=ENGLISH${search ? `&${search}` : q ? `&${q}` : `&reported_by=${2}`}&pagination=${pagination}&limit=${limit}`
    );
  },
  del: (id: string) => requests.del(`Admin/reported/users/${id}`),
  resolveRides: (values: any) => requests.put(`Admin/reported/users`, values),
}

const ManageTransaction = {
  getPagnation: (limit: number, page: number, search: string) => requests.get(`Admin/manage_transactions?limit=${limit}&pagination=${page}${search ? `&${search}` : ""}`)

}

const ManageTransfer = {
  getPagnation: (limit: number, page: number, search: string) => requests.get(`Admin/transfer/payouts?limit=${limit}&pagination=${page}${search ? `&${search}` : ""}`)

}
const Payout = {
  pay: (id: any) => requests.post(`Admin/transfer/${id}`, {}),
}

const ForgotPassword = {
  email: (info: any) => requests.put("User/forgot_password", info)
}

const Vechicle = {
  getListing: (pagination: number, limit: number, id: string, search: string) => {
    return requests.get(
      `Admin/vehicles?pagination=${pagination}&limit=${limit}&language=ENGLISH&${search ? `&search=${search}` : ""}`
    )

  },

  getVehicleDetails: (_id: string) => requests.get(`Admin/user_vehicles/${_id}`),
  // addVehicle: (_id: string, data: any) => requests.put(`Admin/user_vehicles/${_id}`, data)
}
const Riding = {
  pagination: (_id: string, pagination: number, limit: number) => requests.get(`Admin/publish/rides?_id=${_id}&pagination=${pagination}&limit=${limit}`),
}

const RidingDetails = {
  getRideDetails: (_id: string) => requests.get(`Admin/rides/${_id}`),
}
const Administrator = {
  getStaffMemberDetail: (id: string) =>
    requests.get(`Admin/staff_members/details?_id=${id}&language=ENGLISH`),

  addStaffMembers: (values: any) => {
    console.log("values", values);

    return requests.post(`Admin/staff_members/add_edit`, values);
  },
  blockOrDeleteStaff: (values: any) => requests.put("Admin/staff_members/block_delete", values),

  getStaffMembers: (values: any) =>
    requests.get(
      `Admin/staff_members?language=${values.language}&search=${values.search}&pagination=${values.page}&limit=${values.limit}`
    ),

  getPagnation: (page: number, limit: number, filter: string) =>
    requests.get(
      `admin/administration?page=${page}&limit=${limit ? limit : 10}${filter ? filter : ""
      }`
    ),
  changeRole: (info: any) => requests.put("admin/role", info),

  changePassword: (values: any) =>
    requests.put("Admin/change_password", {
      old_password: values.oldPass,
      new_password: values.newPass,
      language: values.language,
    }),

  editImgProfile: (values: any, image: string) =>
    requests.put(`Admin/edit_profile
    `, {
      image: image,
      name: values.name,
      language: values.language,
    }),

  editProfile: (values: any) =>
    requests.put(`Admin/edit_profile
    `, {
      name: values.name,
      image: values.image,
      language: values.language,
    }),

  sendNotifications: (values: any) => requests.post(`Admin/notification/broadcast`, values),
};

const Staff = {
  pagination: (page: number, search: string, role: string) =>
    requests.get(
      `Admin/staff_members/listing?language=ENGLISH${search ? `&search=${search}` : ""
      }${role !== "all" ? `&role=${role}` : ""}&pagination=${Number(page) - 1
      }&limit=10`
    ),
};

const Policies = {
  all: () => requests.get(`Admin/content/listing?language=ENGLISH`),
  byType: (type: string) =>
    requests.get(`Admin/content/listing?type=${type}`),
  addEditPolicy: (values: any) => requests.post(`Admin/content/add_edit`, values),
  delete: (id: string) => requests.del(`Admin/policies/${id}`),
};

const ContactUs = {
  pagination: (page: number, search: string, limit: number) =>
    requests.get(
      `Admin/contact_us/listing?language=ENGLISH${search ? `&${search}` : ""
      }&pagination=${Number(page)}&limit=${limit}`
    ),
  fetchContacts: (values: any) =>
    requests.get(
      `Admin/contact_us?language=${values.language}&search=${values.search}&pagination=${values.page}&limit=${values.limit}`
    ),

  deleteContact: (values: any) => requests.put(`Admin/contact_us/resolve_delete`, values),

  resolveContact: (values: any) => requests.put(`Admin/contact_us/resolve_delete`, values),
};

const Profile = {
  get: () => requests.get(`admin/profile`),
  getById: (id: string) => requests.get(`profile?id=${id}`),
  update: (info: any) => requests.put(`admin/profile`, info),
};
const Divident = {
  release: (info: any) => requests.post(`Admin/dividents/release`, info),
};

const Faq = {
  addFaq: (values: any) => requests.post(`Admin/faqs/add_edit`, values),
  paginationByid: (id: string) =>
    requests.get(
      `Admin/faqs/listing?_id=${id}`
    ),
  editFaq: (values: any) => requests.post(`Admin/faqs/add_edit`, values),
  pagination: (page: number, search: string, limit: number) =>
    requests.get(
      `Admin/faqs/listing?pagination=${Number(page) - 1}${search ? `&${search}` : ""}&limit=${limit}`
    ),
  // getFaqById: (values) =>
  //   requests.get(`Admin/faqs?language=${values.language}&_id=${values._id}`),
  deleteFaq: (id: string) => requests.del(`Admin/faqs/delete/${id}`),

};
const Reports = {
  pagination: (pagination: number) =>
    requests.get(
      `Admin/reported?language=ENGLISH&pagination=${pagination}&limit=10`
    ),
  getFaqById: (values: any) =>
    requests.get(`Admin/reported?language=ENGLISH&_id=${values._id}`),
};

const Common = {
  allUsers: (search: string) =>
    requests.get(
      `Admin/users/listing?language=ENGLISH${search ? `&search=${search}` : ""
      }&pagination=0&limit=10`
    ),
  staticData: () => requests.get(`static-data`),
  getSales: (pagination: number, search: string) =>
    requests.get(
      `Admin/nfts/orders/trade?language=ENGLISH&${search ? `&search=${search}` : ""
      }&pagination=${pagination}&limit=10`
    ),
  export: (start_date: string, end_date: string) =>
    requests.get(
      `Admin/nfts/orders/trade?language=ENGLISH&start_date=${start_date}&end_date=${end_date}`
    ),
  getSalesDetails: (_id: string, pagination: number) =>
    requests.get(
      `Admin/sales?language=ENGLISH&_id=${_id}&pagination=${pagination}&limit=10`
    ),
  all_languages: () => requests.get(`admin/all_languages`),
  all_counrties: () => requests.get(`admin/all_counrties`),
  addCountries: (name: string) => requests.post(`admin/countries`, { name: name }),
  addLanguage: (name: string) => requests.post("admin/languages", { name: name }),
  backupDb: (bodyData: any) =>
    requests.post("Admin/backup/database", bodyData),
};
const Message = {
  send: (items: any) => requests.post("admin/message", items),
};


const FILES = {
  // audio: (filename: string) => filename?.startsWith('http') ? filename : `${API_FILE_ROOT_AUDIO}${filename}`,
  // video: (filename: string) => filename?.startsWith('http') ? filename : `${API_FILE_ROOT_VIDEO}${filename}`,
  imageOriginal: (filename: string) => filename?.startsWith('http') ? filename : `${API_FILE_ROOT_ORIGINAL}${filename}`,
  imageMedium: (filename: string) => filename?.startsWith('http') ? filename : `${API_FILE_ROOT_MEDIUM}${filename}`,
  imageSmall: (filename: string) => filename?.startsWith('http') ? filename : `${API_FILE_ROOT_SMALL}${filename}`,
}

const Vehicle = {
  add: (info: any) => requests.post("Admin/vehicle/brands", info),
  getBrand: (search: string, pagination: number, limit: number) => requests.get(`Admin/vehicle/brands?pagination=${pagination}${search ? `&search=${search}` : ""}&limit=${limit}`),
  getBrandById: (id: string) => requests.get(`Admin/vehicle/brands?_id=${id}`),
  getModals: (name: string, pagination: any, search: string) => requests.get(`Admin/vehicle/models?brand_name=${name}${pagination ? `&pagination=${pagination - 1}` : ""}${search ? `&search=${search}` : ""}&limit=10`),
  edit: (id: string) => requests.get(`Admin/vehicle/brands?_id=${id}`),
  delModal: (id: string) => requests.del(`Admin/vehicle/models/${id}`),
  delBrand: (id: string) => requests.del(`Admin/vehicle/brands/${id}`),
  editBrand: (info: any) => requests.put(`Admin/vehicle/brands`, info),
  editModel: (info: any) => requests.put(`Admin/vehicle/model`, info),
  editVehicle: (info: any) => requests.put(`Admin/vehicle/models`, info),
}

// const Nfts = {
//   export: (start_date:string, end_date:string, timezone:string) =>
//     requests.get(
//       `Admin/nfts?language=ENGLISH&start_date=${start_date}&end_date=${end_date}&timezone=${timezone}`
//     ),

//   pagination: (pagination:number, search:string) =>
//     requests.get(
//       `Admin/nfts?language=ENGLISH${search ? `&search=${search}` : ""
//       }&pagination=${pagination}&limit=9`
//     ),

//   streamingPartner: () =>
//     requests.get(
//       `Admin/stream_partner?language=ENGLISH`
//     ),
//   addeditStreamingPartner: (values:any) =>
//     requests.post(
//       `Admin/stream_partner`, values
//     ),



//   deletePartner: (_id:string) => requests.del(`Admin/stream_partner/${_id}`),

//   nftStreaming: (pagination) =>
//     requests.get(
//       `Admin/streaming?language=ENGLISH&pagination=0&limit=9`
//     ),
//   getstreamingPending: (pagination) =>
//     requests.get(
//       `Admin/streaming?language=ENGLISH&status=PENDING&pagination=0&limit=10`
//     ),
//   getstreamingAccept: (_id, pagination) =>
//     requests.get(
//       `Admin/streaming?language=ENGLISH&status=ACCEPT&pagination=${pagination}&limit=10`
//     ),
//   getstreamingReject: (pagination) =>
//     requests.get(
//       `Admin/streaming?language=ENGLISH&status=REJECT&pagination=0&limit=10`
//     ),

//   nftStreamingDetails: (_id) =>
//     requests.get(
//       `Admin/streaming/${_id}?language=ENGLISH`
//     ),
//   dividents: () =>
//     requests.get(
//       `Admin/dividents?language=ENGLISH`
//     ),
//   payDividents: (values) =>
//     requests.post(
//       `Admin/dividents`, values
//     ),

//   dividentsDetails: (_id) =>
//     requests.get(
//       `Admin/dividents/${_id}?language=ENGLISH`
//     ),
//   dividentsUserTransaction: (_id, user_id) =>
//     requests.get(
//       `Admin/dividents/user_transaction?_id=${_id}&user_id=${user_id}&language=ENGLISH`
//     ),
//   collectionlisting: (pagination) =>
//     requests.get(
//       `collection/hot?language=ENGLISH&limit=9&pagination=${pagination}`
//     ),
//   collectionDetails: (_id) =>
//     requests.get(
//       `collection/${_id}`
//     ),
//   collectionNftDetails: (_id) =>
//     requests.get(
//       `Nft/explore?collection_id=${_id}&language=ENGLISH`
//     ),



//   editStreaming: (values) => requests.put(`Admin/streaming`, values),




//   reports: (_id) =>
//     requests.get(`Admin/nfts/reported?language=ENGLISH&nft_id=${_id}`),
//   allReports: (_id) => requests.get(`Admin/nfts/reported?language=ENGLISH`),
//   details: (_id, graph_type, timezone) =>
//     requests.get(
//       `Admin/nfts/details?language=ENGLISH&_id=${_id}&graph_type=${String(
//         graph_type
//       ).toUpperCase()}`
//     ),
//   delete: (info) => requests.put(`Admin/nfts`, info),
//   recovery: (info) => requests.put(`Admin/nfts/recovery`, info),
// };

export default {
  token,
  Booking,
  ContactUs,
  Divident,
  Faq,
  Reports,
  Auth,
  Administrator,
  Staff,
  Common,
  Profile,
  RidingDetails,
  ForgotPassword,
  CarListing,
  Dashboard,
  Facility,
  BookingListing,
  Vechicle,
  Transaction,
  PromoCode,
  Vehicle,
  User,
  Riding,
  Policies,
  Payout,
  Rides,
  FILES,
  ManageTransaction,
  ManageTransfer,
  Message,
  Report,
  Coupons,
  // Nfts,
  API_ROOT,
  API_FILE_ROOT_AUDIO,
  API_FILE_ROOT_SMALL,
  API_FILE_ROOT_MEDIUM,
  API_FILE_ROOT_ORIGINAL,
  API_FILE_ROOT_VIDEO,
  setToken: (_token: any) => {
    token = _token;
  },
};
