import React, { useContext, useState } from 'react'
import henceforthApi from '../utils/henceforthApi';
import { NumberValidation } from '../utils/validations';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom'
import moment from 'moment';
import { toast } from 'react-toastify';
import { GlobalContext } from '../context/Provider';
import henceofrthEnums from '../utils/henceofrthEnums';

interface addcoupon {
  code: string,
  type: string
  amount: number,
  usesTimes: string,
  expire: string,
  currency: string
}

function CouponsAdd() {
  const [loading, setLoading] = useState(false);
  const PERCENTAGE_COUPONS = 'PERCENTAGE'
  const FIXED_COUPONS = 'FIXED'
  const [coupons, setCoupons] = useState({
    code: "",
    type: "",
    amount: 0,
    usesTimes: "",
    expire: "",
    currency: "CAD"
  } as addcoupon)
  let navigate = useNavigate()
  const handleChange = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;
    setCoupons({
      ...coupons,
      [name]: value
    })
    NumberValidation(String(coupons.amount))
  }

  console.log("coupon", coupons)

  const handleSubmit = async () => {
    // setDisabledLoading(true)
    const code = coupons.code.trim()
    const day = coupons.usesTimes.trim()
    if (!code || !day) {
      return toast.warn('Spaces are not allowed as character')
    }
    if (!NumberValidation(day)) {
      return toast.warn('Time uses in Number')
    }
    if (Number(coupons.amount) == 0) {
      return toast.warn('Coupons amount cannot be 0')
    }
    const items = {
      name: code,
      expire_date: coupons.expire,
      use_time: Number(day),
      coupon_type: coupons.type,
    } as any

    console.log("items", items)
    if (coupons.type === FIXED_COUPONS) {
      items['price'] = Number(coupons.amount)
      items['currency'] = coupons.currency ?? 'CAD'
    } else {
      items['max_discount'] = Number(coupons.amount)
    }
    try {
      setLoading(true)
      if (coupons.amount <= 5) {
        await henceforthApi.Coupons.getCoupons(items).then((res: any) => {
          Swal.fire({
            title: "Coupon Added",
            text: "Coupon Added successfully",
            icon: "success",
            confirmButtonText: "OK",
          })
          navigate("/coupons/1")
          // CouponList()
        }).catch((err: any) => {
          console.log("delte failed", err);
          Swal.fire({
            title: "Coupon Added Failed",
            text: err.response.body.error_description || err.error_description.message,
            icon: "error",
            confirmButtonText: "Cancel",
          });
        });
        setCoupons({
          code: "",
          type: "",
          amount: "",
          usesTimes: "",
          expire: "",

        } as any)
      }
      else {
        toast.error(`Maximum discount allowed is ${coupons.type == "FIXED" ? coupons.currency == henceofrthEnums.CurrencyType.NGN ? '₦ 5' : '$ 5' : `5 %`} `)
      }
      setLoading(false)
    }
    catch {
      // toast.error("please enter less than 5 discount")
      console.log("error")
      setLoading(false)
    }
  }
  return (
    <>
      <div className='row font' >
        <div className='col-3 me-auto'>
          <b><h2>Create-Coupons</h2></b>
        </div>
        <div className='col-1'>
          <button className='btn btn-color ms-auto' onClick={() => { window.history.back() }}>Back</button>
        </div>
      </div>
      <div className='container'>
        <form onSubmit={(e: any) => { e.preventDefault(); handleSubmit() }}>
          <div className='row mt-4'>
            <div className='col-5 font'>
              <label>Enter Coupon code</label>
              <br></br>
              <input type="text" className='form-control' value={coupons.code} onChange={handleChange} name="code" placeholder='Enter coupons code' required />
            </div>
            <div className='col-5 font'>
              <label>Type</label>
              <br></br>
              <select className="form-select me-3" value={coupons.type} onChange={handleChange} name="type" required>
                <option value={''} disabled>Choose Type</option>
                <option value={FIXED_COUPONS}>FIXED</option>
                <option value={PERCENTAGE_COUPONS}>PERCENTAGE</option>
              </select>
            </div>
            <div className="col-5 mt-3 font">
              <label>Coupon amount</label>
              <br></br>
              <div className="input-group mb-2">
                <div className="input-group-prepend">
                  <div className="input-group-text">
                    {coupons.type == "FIXED" ?

                      <select className="form-select coupens-wrapper" aria-label="Default select example" value={coupons.currency} onChange={handleChange} name="currency" >
                        {/* <option disabled className='border-bottom'>Open this select menu</option> */}
                        <option value="CAD">$</option>
                        <option value="NGN">₦</option>
                      </select>
                      : "%"}</div>
                </div>
                <input type="text" className="form-control"
                  value={coupons.amount} maxLength={coupons.type == "FIXED" ? 3 : 3} id="input1" onChange={handleChange} name="amount" placeholder={coupons.type == "FIXED" ? "Enter coupons amount" : "Enter coupons percentage"} required

                />
              </div>
            </div>
            <div className='col-5 font mt-3'>
              <label>No. of times uses</label>
              <br></br>
              <input type="text" className='form-control' value={coupons.usesTimes} onChange={handleChange} name="usesTimes" placeholder='Enter Uses Days' required />
            </div>
            <div className='col-5 font'>
              <label>Expire Date</label>
              <br></br>
              <input type="date" className='form-control' min={moment().format("YYYY-MM-DD")} value={coupons.expire} onChange={handleChange} name="expire" placeholder='Enter expire Date' required />
            </div>
          </div>
          <button type="submit" disabled={loading} className='btn btn-color mt-4'>Save</button>
        </form>
      </div>
    </>
  )
}

export default CouponsAdd;
