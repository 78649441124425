import React from "react";
import PaginationLayout from "../components/PaginationLayout";
import { Link, useLocation, useMatch } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import henceforthApi from "../utils/henceforthApi";
import { GlobalContext } from "../context/Provider";
import moment from "moment";
import DownloadfileModal from "../components/common/downloadfileModal";
import henceofrthEnums from "../utils/henceofrthEnums";
interface translist {
  order_id: string,
  customer_name: string
  driver_name: string,

}
interface transaction {
  id: string
  booking_detail: translist
  amount: number
}

const ManageTransaction = ({ date }: any) => {
  const location = useLocation();
  let limit = 10;
  const { authState, handlePagination, handleFilter, handleError } = useContext(GlobalContext);
  const params: any = useParams();
  const newParam = new URLSearchParams(location.search);
  const [loading, setLoading] = useState(false);
  const [pageData, setPageData] = useState<Array<transaction>>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [start_date, setStartDate] = useState(0);
  const [end_date, setEndDate] = useState(0);

  if(authState.access_token){henceforthApi.setToken(authState.access_token);}
  const match = useMatch("/transactions/:page")

  const exportData = async (start_date: number, end_date: number) => {
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    try {
      let apiRes = await henceforthApi.Transaction.export(
        moment(start_date).format("DD/MM/YYYY"),
        moment(end_date).format("DD/MM/YYYY"),
        // timezone
      );
      let data = apiRes.data;
      console.log(data);
      const rows = [
        [
          "Sr No.",
          "Transaction Id",
          "Passenger",
          "Driver",
          "Amount"
        ],
      ];
      if (Array.isArray(data)) {
        data.map((res: any, index: number) => {
          rows.push([
            index + 1,
            res?.order_id,
            res?.customer_name,
            res?.driver_name,
            res?.amount,
          ]);
        });
      }
      console.log(rows);

      let csvContent =
        "data:text/csv;charset=utf-8," +
        rows.map((e: any) => e.join(",")).join("\n");
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `user_${moment().valueOf()}.csv`);
      document.body.appendChild(link); // Required for FF

      link.click(); // This will download the data file named "my_data.csv".
      let closeModal = document.getElementById("closeModal");
      if (closeModal) {
        closeModal.click();
      }
    } catch (error: any) { }
  };
  useEffect(() => {
    const init = async () => {
      setLoading(true);
      try {
        setLoading(true)
        let apiRes = await henceforthApi.ManageTransaction.getPagnation(
          limit,
          Number(params.page) - 1,
          newParam.toString()
        );
        setTotalCount(apiRes.data.total_count);
        setPageData(apiRes.data.data);
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        setPageData([]);
        setTotalCount(0);
        handleError(error)
      }
    };
    init();
  }, [params.page, newParam.get("search")]);
  return (
    <>
      <div className="ibox animated fadeInRight">
        <div className="ibox-title d-flex align-items-center justify-content-between">
          <h5>List of all Transactions</h5>
          <div className="d-flex align-items-center justify-content-end ">
            <button
              className="btn btn-primary-button mb-1 ms-4"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              Export
            </button>
          </div>
        </div>
        <div className="ibox-content">
          <form
            onSubmit={(e: any) => {
              e.preventDefault();
            }}>
            <div className="input-group form-group">
              <input
                name="search"
                onChange={(e: any) => {
                  handleFilter(e.target.name, e.target.value);
                }}
                placeholder="Search by Passenger,Driver or Transaction id"
                type="text"
                className="form-control form-control-sm"
              />{" "}
            </div>
          </form>
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Sr No.</th>
                  <th>Transaction Id</th>
                  <th>Passenger</th>
                  <th>Driver</th>
                  <th>Amount</th>
                </tr>
              </thead>
              {
                !loading &&
                <tbody className="">
                  {Array.isArray(pageData) && pageData.length > 0 ? pageData?.map((res: any, index: number) => {
                    return (
                      <tr key={res.id}>
                        <td>
                          {params.page === "0"
                            ? index + 1
                            : (params.page - 1) * limit + (index + 1)}.
                        </td>
                        <td className="p-3">{res?.booking_detail?.order_id ? res?.booking_detail?.order_id : "Not available"}</td>
                        <td>{res?.booking_detail?.customer_name ? res?.booking_detail?.customer_name : "Not available"}</td>
                        <td>{res?.booking_detail?.driver_name ? res?.booking_detail?.driver_name : "Not available"}</td>
                        <td>{res?.amount ? res?.currency == henceofrthEnums.CurrencyType.NGN ? `₦ ${res?.amount}` : `$ ${res?.amount}` : "Not available"}</td>
                      </tr>
                    );
                  }) : <tr> <td colSpan={12} className="text-center">
                    Sorry! no data to display
                  </td> </tr>}
                </tbody>
              }

            </table>
          </div>
          <PaginationLayout
            count={totalCount}
            data={pageData}
            page={Number(params.page)}
            limit={Number(limit)}
            loading={loading}
            onPageChange={(val) => handlePagination(val)}
          />
        </div>
      </div>
      <DownloadfileModal data={exportData} />
    </>
  )
}

export default ManageTransaction;