import { Bar } from "react-chartjs-2";
import Graphs from "../Graphs";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import henceforthApi from "../../utils/henceforthApi";




export default ({ dataset, labels, title }:any) => {
  const[priceHistory,setPriceHistory]=useState({
    data: []
  })
  const location = useLocation()
  const navigate = useNavigate()
  const searchParam = new URLSearchParams(location.search)
  const changeGraphType = (type:string) => {
    const newParam = new URLSearchParams()
    if (type) {
      newParam.set("type", type)
    }
    navigate({ search: newParam.toString() })
  }
  // const initialiseQuery = (type:string) => {
  //   const newParam = new URLSearchParams()
  //   if (type) {
  //     newParam.set("type", type)
  //   }
  //   navigate({ search: newParam.toString() })
  // }
  const data = {
    labels,
    datasets: [
      {
        label: 'Price',
        data: dataset,
        borderColor: '#32CD32',
        borderWidth: 1,
        backgroundColor: '#32CD32',
      },
    ],
  };
  const initialPriceHistory=async()=>{
    try{
 const res= await henceforthApi.Dashboard.getTradingPriceById(searchParam.get("type"))
 console.log((res));
 setPriceHistory({
  ...priceHistory,
  ...res.data
 })
 
    }
    catch{
}
  }
  useEffect(()=>{
    initialPriceHistory()
  },[searchParam.get("type")])

  return (
    <div className="ibox">
       {/* <h5>Time vs Sales</h5> */}
      <div className="ibox-title d-flex justify-content-between">
        <h5>Time vs Sales</h5>
        <div className="ibox-tools">
        <div className="downn-arrow position-relative text-end">
          <i className="far fa-angle-down position-absolute theme-color"></i>
          <select className="trant-btn btn text-black py-0 dashboard-outline-button pe-4"  name="month" onChange={(e) => changeGraphType(String(e.target.value))} value={String(searchParam.get("type"))}>
          <option value="DAILY" className="theme-color">Daily</option>
            <option value="WEEKLY" className="theme-color">Weekly</option>
            <option value="MONTHLY" className="theme-color">Monthly</option>
            <option value="YEARLY" className="theme-color">Yearly</option>
             </select>
        </div>
        </div>
      </div>
      <div className="ibox-content">
      <div className="row">
        <div className="col-md-6 offset-md-3">
          <div className="chartjs w-100 d-flex justify-content-center line-chart-box">
            {<Graphs.LineChart {...priceHistory} />}
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}