import placeholder from '../../assets/images/profile_placeholder.png'
import henceforthApi from '../../utils/henceforthApi';
const MaskImage = ({ image, profile }: any) => {
    return (
        <>
            <button data-bs-toggle="modal" data-bs-target={`#exampleModal${image && image.replaceAll(".","")}`} className={`mask-image btn text-white fs-6 d-flex align-items-center justify-content-center ${profile ? 'rounded-0' : ''}`}>
                <i className="fa fa-eye"></i>
            </button>

            <div className="modal fade preview-modal" id={`exampleModal${image && image.replaceAll(".","")}`} aria-labelledby={`exampleModal${image && image.replaceAll(".","")}Label`} tabIndex={-1}>
                <div className="modal-dialog modal-dialog-centered ">
                    <div className="modal-content position-relative">
                        <div className="d-inline-block position-absolute top-0 end-0 mt-2 me-2" style={{ zIndex: 1 }}>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-0 rounded-2">
                            <img
                                key={image}
                                src={
                                    image
                                        ? `${henceforthApi.API_FILE_ROOT_ORIGINAL}${image}`
                                        : placeholder
                                }
                                alt="Not Found" className='img-fluid rounded-2' style={{ objectFit: 'cover' }} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MaskImage;