import PaginationLayout from "../components/PaginationLayout";
import { Link, useLocation, useMatch } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import henceforthApi from "../utils/henceforthApi";
import { GlobalContext } from "../context/Provider";
import moment from "moment";
import DownloadfileModal from "../components/common/downloadfileModal";
import profile_placeholder from "../assets/images/profile_placeholder.png";
import Swal from 'sweetalert2';
import { toast } from "react-toastify";
import henceofrthEnums from "../utils/henceofrthEnums";
interface translist {
    order_id: string,
    customer_name: string
    driver_name: string,

}
interface transaction {
    id: string
    booking_detail: translist
    amount: number
}

const ManageTransfer = ({ date }: any) => {
    const location = useLocation();
    let limit = 10;
    const { authState, handlePagination, handleFilter, handleError } = useContext(GlobalContext);
    const params: any = useParams();
    const newParam = new URLSearchParams(location.search);
    const [loading, setLoading] = useState(false);
    const [pageData, setPageData] = useState<Array<transaction>>([]);
    const [totalCount, setTotalCount] = useState(0);
    const [payoutLoading, setPayoutLoading] = useState(false);

    if (authState.access_token) { henceforthApi.setToken(authState.access_token); }
    const match = useMatch("/transactions/:page")

    const exportData = async (start_date: number, end_date: number) => {
        let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        try {
            let apiRes = await henceforthApi.Transaction.export(
                moment(start_date).format("DD/MM/YYYY"),
                moment(end_date).format("DD/MM/YYYY"),
                // timezone
            );
            let data = apiRes.data;
            console.log(data);
            const rows = [
                [
                    "Sr No.",
                    "Transaction Id",
                    "Passenger",
                    "Driver",
                    "Amount"
                ],
            ];
            if (Array.isArray(data)) {
                data.map((res: any, index: number) => {
                    rows.push([
                        index + 1,
                        res?.order_id,
                        res?.customer_name,
                        res?.driver_name,
                        res?.amount,
                    ]);
                });
            }
            console.log(rows);

            let csvContent =
                "data:text/csv;charset=utf-8," +
                rows.map((e: any) => e.join(",")).join("\n");
            var encodedUri = encodeURI(csvContent);
            var link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", `user_${moment().valueOf()}.csv`);
            document.body.appendChild(link); // Required for FF

            link.click(); // This will download the data file named "my_data.csv".
            let closeModal = document.getElementById("closeModal");
            if (closeModal) {
                closeModal.click();
            }
        } catch (error: any) { }
    };

    const init = async () => {
        setLoading(true);
        try {
            setLoading(true)
            let apiRes = await henceforthApi.ManageTransfer.getPagnation(
                limit,
                Number(params.page) - 1,
                newParam.toString()
            );
            setTotalCount(apiRes.data.count);
            setPageData(apiRes.data.data);
            setLoading(false);
        } catch (error: any) {
            setLoading(false);
            setPageData([]);
            setTotalCount(0);
            handleError(error)
        }
    };

    useEffect(() => {
        init();
    }, [params.page, newParam.get("search")]);

    const pay = (id: string) => {
        Swal.fire({
            title: "Do you want to pay this driver?",
            text: "",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                let apiRes = await henceforthApi.Payout.pay(id)
                console.log(apiRes, 'apiRes');
                return apiRes
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result: any) => {
            if (result?.isConfirmed) {
                Swal.fire({
                    title: "Payment Successfull !",
                    text: "",
                    icon: "success",
                    confirmButtonText: "OK",
                })
                init()
            }
        }).catch((error) => {
            handleError(error)
            Swal.close()
        })
    }

    return (
        <>
            <div className="ibox animated fadeInRight postion-relative">
                <div className="ibox-title d-flex align-items-center justify-content-between">
                    <h5>List of Transfers</h5>
                    <div className="d-flex align-items-center justify-content-end ">
                        {/* <button
                            className="btn btn-primary-button mb-1 ms-4"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                        >
                            Export
                        </button> */}
                    </div>
                </div>
                <div className="ibox-content">
                    <form
                        onSubmit={(e: any) => {
                            e.preventDefault();
                        }}>
                        <div className="input-group form-group">
                            <input
                                name="search"
                                onChange={(e: any) => {
                                    handleFilter(e.target.name, e.target.value);
                                }}
                                placeholder="Search"
                                type="text"
                                className="form-control form-control-sm"
                            />{" "}
                        </div>
                    </form>
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>Sr No.</th>
                                    <th>Driver Name</th>
                                    <th>Driver Email</th>
                                    <th>Admin Earn</th>
                                    <th>Driver Earn</th>
                                    <th>Total Amount</th>
                                    <th>Payment Done</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            {
                                !loading &&
                                <tbody className="">
                                    {Array.isArray(pageData) && pageData.length > 0 ? pageData?.map((res: any, index: number) => {
                                        return (
                                            <tr key={res.id}>
                                                <td>
                                                    {params.page === "0"
                                                        ? index + 1
                                                        : (params.page - 1) * limit + (index + 1)}.
                                                </td>
                                                <td>
                                                    <div className="text-nowrap">
                                                        <img
                                                            src={
                                                                res?.driver_detail?.profile_pic !== 'null' && res?.driver_detail?.profile_pic !== null ? henceforthApi.FILES.imageOriginal(res?.driver_detail?.profile_pic) : profile_placeholder
                                                            }
                                                            className="me-2 imageSize" alt="" />
                                                        {res?.driver_detail?.name ?? `Not Available`}
                                                    </div>
                                                </td>
                                                <td>
                                                    {res?.driver_detail?.email ?? "Not available"}
                                                </td>
                                                <td>{res?.admin_earns ? res?.currency == henceofrthEnums.CurrencyType.NGN ? `₦ ${res?.admin_earns}` : `$ ${res?.admin_earns}` : "Not available"}</td>

                                                <td>{res?.driver_earns ? res?.currency == henceofrthEnums.CurrencyType.NGN ? `₦ ${res?.driver_earns}` : `$ ${res?.driver_earns}` : "Not available"}</td>

                                                <td>{res?.total_amount ? res?.currency == henceofrthEnums.CurrencyType.NGN ? `₦ ${res?.total_amount}` : `$ ${res?.total_amount}` : "Not available"}</td>

                                                <td>{res?.is_transferred ? 'Yes' : 'No'}</td>
                                                <td>
                                                    <div className="btn-group">
                                                        <button disabled={res?.is_transferred ? true : false}
                                                            onClick={() => pay(res._id)}
                                                            className="btn-white btn btn-xs me-2"
                                                        >
                                                            {res?.is_transferred ? 'Paid' : 'Pay'}
                                                        </button>

                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    }) : <tr> <td colSpan={12} className="text-center">
                                        Sorry! no data to display
                                    </td> </tr>}
                                </tbody>
                            }

                        </table>
                    </div>
                    <PaginationLayout
                        count={totalCount}
                        data={pageData}
                        page={Number(params.page)}
                        limit={Number(limit)}
                        loading={loading}
                        onPageChange={(val) => handlePagination(val)}
                    />
                </div>

                {/* Loading Button */}
                {/* <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div> */}

            </div>
            <DownloadfileModal data={exportData} />
        </>
    )
}

export default ManageTransfer;