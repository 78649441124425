import { LOGOUT_SUCCESS, STATIC_DATA_DASHBOARD, STATIC_DATA_NFTS, STATIC_DATA_SUCCESS } from "../../constants/actionTypes";

const static_data = (state:any, { type, payload }:any) => {
    switch (type) {
        case STATIC_DATA_SUCCESS:
            return {
                ...state,
                ...payload,
                loaded: true
            }
        case STATIC_DATA_DASHBOARD:
            return {
                ...state,
                dashboard: { ...payload, loaded: true },
            }
        case STATIC_DATA_NFTS:
            return {
                ...state,
                nfts: { ...payload, loaded: true },
            }
        case LOGOUT_SUCCESS:
            return {
                ...state,
                ...payload,
            }
    }
}

export default static_data;