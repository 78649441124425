import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Login from "./activity/Login";
import ForgotPassword from "./activity/ForgotPassword";
import MainActivity from "./activity/MainActivity";
import { GlobalContext } from "./context/Provider";
import React, { useContext, useState } from "react";
import { ToastContainer } from "react-toastify";
import { GlobalLoading } from "./components/BootstrapCompo";
import henceforthApi from "./utils/henceforthApi";
import ResetPassword from "./activity/ResetPassword";

export default () => {
  let navigate = useNavigate();
  let location = useLocation();

  const { authState, loading, disabledLoading } = useContext(GlobalContext);

    if(authState.access_token){henceforthApi.setToken(authState.access_token);}

  return (
    <>
      {authState?.access_token ? (
        <Routes>
          <Route path="/" element={<MainActivity />}>
            <Route path="/" />
            <Route path="/contents" />
            <Route path="/policies/add" />
            <Route path="/policies/:type" />
            <Route path="/policies/:type/edit" />
            <Route path="/contact-us/:page" />
            <Route path="/reports/nft/:page" />
            <Route path="/reports/users/:page" />
            <Route path="/notifications" />
            <Route path="/coupons/add" />
            <Route path="/transactions/:page" />
            <Route path="/transfer/:page" />
            <Route path="/booked-rides/:page" />
            <Route path="/booking-details/:page" />
            <Route path="/coupons/:page" />
            <Route path="/profile" />
            <Route path="/vehicle/add" />
            <Route path="/report-user/:page" />
            <Route path="/profile/edit" />
            <Route path="/vehicles/:page" />
            <Route path="/staff/:page/:role" />
            <Route path="/staff/:id" />
            <Route path="/staff/edit/:id" />
            <Route path="/created-rides/:page" />
            <Route path="/ride-details/:id" />
            <Route path="/staff/add" />
            <Route path="/email-template" />
            <Route path="/add-template" />
            <Route path="/edit-template" />
            <Route path="/change-password" />
            <Route path="/user-vehicle/:page" />
            {/* <Route path="/vehicle-details/:id" /> */}
            <Route path="/users/:page" />
            <Route path="/auction-orders/:page" />
            <Route path="/collection-details/:id" />
            <Route path="/trade-orders/:page" />
            <Route path="/dividend-management/:page" />
            <Route path="/dividend-Details/:id" />
            <Route path="/pay-dividend/:id" />
            <Route path="/user/:id" />
            <Route path="/products/:page" />
            <Route path="/product/:id" />
            <Route path="/nft-streaming/:page" />
            <Route path="/request-streaming/:page" />
            <Route path="/request-streaming-Details/:id" />
            <Route path="/edit-streaming/:id" />
            <Route path="/streaming-partner/:page" />
            <Route path="/streaming-partner-edit/:id" />
            <Route path="/manage-streaming/:page" />
            <Route path="/manage-streaming-Details/:id" />
            <Route path="/user-transaction/:id/:user_id" />
            <Route path="/language" />
            <Route path="/FAQs/:page" />
            <Route path="/faq/edit/:id" />
            <Route path="/faq/add" />
            <Route path="/language/edit/:id" />
            <Route path="/db-backup" />
            <Route path="/vehicle/edit/:id" />
            <Route path="/change-password" />
            <Route path="/db-clear" />
            <Route path="/creators/:page" />
            <Route path="/vehicle-Details/:page/:id" />
            <Route path="/creator/:id" />
            <Route path="/ride-create/:page" />
            <Route path="/ride-booking/:page" />
            <Route path="/creator/:id/photos/public/:page" />
            <Route path="/creator/:id/photos/private/:page" />

          </Route>
        </Routes>
      ) : (
        <Routes>
          <Route
            path="/password/forgot"
            element={<ForgotPassword navigate={navigate} location={location} />}
          />
          <Route
            path="/password/reset/:code"
            element={<ResetPassword navigate={navigate} location={location} />}
          />
          <Route
            path="*"
            element={<Login navigate={navigate} location={location} />}
          />
        </Routes>
      )}
      {loading ? <GlobalLoading /> : ""}
      <ToastContainer autoClose={500} limit={1} />
    </>
  );
};
