import React, { useRef } from 'react'
import { useState } from 'react';
import henceforthApi from '../utils/henceforthApi';
import Vehicle from './Vehicle';
import Swal from 'sweetalert2';
import { Navigate, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";

function AddVehicle() {
  const [vehicle, setVehicle] = useState("")
  const [modal, setModal] = useState('')
  const spanRef = useRef() as React.MutableRefObject<any>
  const modalRef = useRef() as React.MutableRefObject<any>
  const [storeModal, setStoreModal] = useState<Array<any>>([])
  // const [mapModal, setMapModal] = useState()
  console.log("modal", modal)
  console.log("storeModal", storeModal)
  console.log("modalRef", modalRef.current)
  console.log("vehicle", vehicle)
  let navigate = useNavigate()
  const handleChange = (e: any) => {

    setVehicle(e.target.value)
  }
  // let upperCasemodel = modal.charAt(0).toUpperCase() + modal.slice(1).toLowerCase()
  const AddModal = () => {
    let modalVal = modal.trim()
    if (!modalVal) {
      return toast.warn('Spaces are not allowed as character')
    }
    setStoreModal([...storeModal, modalVal])
    setModal("")
    spanRef.current.style.display = "none"
  }
  const handleSubmit = async () => {
    let vehicleVal = vehicle.trim()
    if (!vehicleVal) {
      return toast.warn('Spaces are not allowed as character')
    }
    let upperCasebrand = vehicleVal.charAt(0).toUpperCase() + vehicleVal.slice(1).toLowerCase()
    // let upperCasemodel = storeModal.charAt(0).toUpperCase() + storeModal.slice(1).toLowerCase()
    const items = {
      name: upperCasebrand,
      models: storeModal
    }
    try {
      let res = await henceforthApi.Vehicle.add(items).then((res: any) => {
        Swal.fire({
          title: "Vehicle Added",
          text: "Vehicle Added Successfully",
          icon: "success",
          confirmButtonText: "OK",
        })
        navigate("/vehicles/1")
      }).catch((err: any) => {
        console.log("delte failed", err);
        Swal.fire({
          title: "Vehicle Added Failed",
          text: err.response.body.error_description,
          icon: "error",
          confirmButtonText: "Cancel",
        });
      });
      console.log(res)
      setVehicle("")
      setModal("")
      setStoreModal([])
    }
    catch (err: any) { console.log("err") }
  }
  let val = modalRef?.current?.value
  const modalChange = (e: any) => {
    console.log("e", e.target.value)
    setModal(e.target.value)
    if (storeModal.map((item) => { return item }) == (e.target.value)) {
      spanRef.current.style.display = "block"
    }
    else {
      spanRef.current.style.display = "none"
    }
  }
  return (
    <>
      <div className='row font' >
        <div className='col-3 me-auto'>
          <b> <h2 className=''>Add Vehicle</h2></b>
        </div>
        <div className='col-1'>
          <button className='btn btn-color ms-auto' onClick={() => { window.history.back() }}>Back</button>
        </div>
      </div>
      <div className='container mt-4'>
        <form onSubmit={(e: any) => { e.preventDefault(); handleSubmit() }}>
          <div className='row'>
            <div className='col-5 font'>
              <label>Enter Vehicle Brand</label>
              <br></br>
              <input type="text" className='form-control' value={vehicle}
                required onChange={handleChange} name="brand" placeholder='Enter Vehicle Brand' />
            </div>
            <div className='col-5'>
              <div className='col-8 font'>
                <label>Enter Vehicle Model</label>
                <br></br>
                <input type="text" ref={modalRef} className='form-control' required
                  value={modal} onChange={modalChange} name="modal" placeholder='Enter Vehicle Model' />
                <span ref={spanRef} style={{ display: "none", color: "red" }}>
                  {
                    modal ? 'This model is already added' : 'Please enter Model'
                  }
                </span>
              </div>
              <div className='col-8 font mt-2'>
                <br></br>
                {/* <input type="text" className='form-control' value={vehicle.modal} onChange={handleChange} name="modal" placeholder='Enter Vehicle Modal'  /> */}
                {storeModal.map((item) => { return <div> <label> Vehicle Model : </label> {item}</div> })}
              </div>
            </div>
            <div className='col-2'>
              <button className='btn btn-color mt-4' type='button' onClick={AddModal} disabled={modal == "" ? true : false || storeModal.map((item) => { return item }) == (val) ? true : false} >Add model</button>
            </div>
            <div className='col-3'>
              <button type="submit" className='btn btn-color mt-4' disabled={!storeModal.length}>Save</button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default AddVehicle;
