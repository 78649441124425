import React, { useState } from "react";
import { Link } from "react-router-dom";
import henceforthApi from "../utils/henceforthApi";
import Swal from "sweetalert2";
import { GlobalContext } from "../context/Provider";
import { useContext } from "react";
import { toast } from "react-toastify";

const ReactQuill =
  typeof window === "object" ? require("react-quill") : () => false;

export default () => {
  const { authState, authDispatch } = useContext(GlobalContext);

  if(authState.access_token){henceforthApi.setToken(authState.access_token);}

  let [faq, setFaq] = useState({
    title: "",
    content: "",
  });

  const handleChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;

    setFaq({
      ...faq,
      [name]: value,
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const content = faq.content.replace(/<(.|\n)*?>/g, '').trim().length
    const title = faq.title.trim()
    if (!title || !content) {
      return toast.warn('Spaces are not allowed as character')
    }
    const data = {
      question: title,
      answer: faq.content.trim(),
      language: "ENGLISH",
    };
    try {
      const response = await henceforthApi.Faq.addFaq(data)
      Swal.fire({
        title: "FAQ Added",
        text: "FAQ added successfully",
        icon: "success",
        confirmButtonText: "OK",
      });
      window.history.back();
    } catch (err: any) {
      console.log("error is ", err);
      Swal.fire({
        title: "Failed!",
        text: err.response.body.error_description,
        icon: "error",
        confirmButtonText: "Cancel",
      });
    }
  };

  return (
    <div className="row animated fadeInRight">
      <div className="col-md-12">
        <div className="ibox">
          <div className="ibox-title p-3">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4>Add FAQs</h4>
              </div>
              <div className="col-auto">
                {/* <Link to="/profile" className="btn-primary-button btn back-btn">
                  <i className="fas fa-trash me-1"></i>Delete
                </Link> */}
              </div>
            </div>
          </div>
          <div className="ibox-content">
            <form onSubmit={handleSubmit}>
              <div className="form-row row">
                <div className="form-group col-md-12">
                  <label>Title</label>
                  <input
                    // pattern="^[^-\s][a-zA-Z0-9_\s-]+$"
                    title="Please enter a valid title"
                    required
                    type="text"
                    className="form-control text-black"
                    name="title"
                    value={faq.title}
                    onChange={(e: any) => handleChange(e)}
                  />
                </div>
                <div className="form-group col-md-12">
                  <label>Content</label>

                  <ReactQuill
                    theme="snow"
                    onChange={(e: any) => setFaq({ ...faq, content: e })}
                    placeholder={`Write your content`}
                    value={faq.content}
                    className="text-black"
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-4 col-sm-offset-2">
                  <button
                    className="btn btn-primary-button btn-sm ms-2"
                    type="submit"
                  >
                    Add
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
