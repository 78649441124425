import React,{ useEffect, useState, useContext } from "react";
import henceforthApi from "../utils/henceforthApi";
import { GlobalContext } from "../context/Provider";
import Swal from "sweetalert2";
import PaginationLayout from "../components/PaginationLayout";
import { Link, useLocation, useMatch, useNavigate } from "react-router-dom";
interface reportby{
  account_type:string
  user_name:string,
  _id:string
}
interface user_id{
  _id:string
  user_name:string
  account_type:string
}
interface userReport{
  reported_by:reportby
  user_id:user_id
  reason:string
  _id:string
}
export default () => {
  const navigate = useNavigate();
  const location = useLocation();
  const match:any = useMatch("/reports/users/:page");

  const newParam = new URLSearchParams(location.search);

  const { authState } = useContext(GlobalContext);
  const [state, setState] = useState({
    data: [] as Array<userReport>,
    total_count: 0,
  });

  const [readContent, setReadContent] = useState("");
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState("");
  if(authState.access_token){henceforthApi.setToken(authState.access_token);}

  const onChangePagination = (newVal:number) => {
    if (newParam.has("search")) {
      navigate(`/reports/users/${newVal}?search=${newParam.get("search")}`);
    } else {
      navigate(`/reports/users/${newVal}`);
    }
  };

  const onClearSearch = () => {
    const newSearchparams = new URLSearchParams();
    navigate({ search: newSearchparams.toString() });
  };
  const onSearch = async (searchData:string) => {
    if (searchData) {
      navigate(`/reports/users/1?search=${searchData}`);
    } else {
      navigate(`/reports/users/1`);
    }
  };
  const initialiseData = async () => {
    try {
      let apiRes = await henceforthApi.Reports.pagination(
        Number(match.params.page) - 1
      );

      setState({
        ...state,
        ...apiRes.data,
      });
      // setLoading(false);
    } catch (error:any) {
      // setLoading(false);
    }
  };
  useEffect(() => {
    const init = async () => {
      // setLoading(true);
      let searchData = newParam.has("search") ? newParam.get("search") : "";
      initialiseData();
    };
    init();
  }, [match.params.page, newParam.get("search")]);

  const handleResolve = (id:string) => {
    const data = {
      language: "ENGLISH",
      type: "RESOLVE",
      _id: id,
    };
    console.log("resolved");

    henceforthApi.ContactUs.resolveContact(data)
      .then((res:any) => {
        console.log(res, "Resolved succesfully");
        Swal.fire({
          title: "Contact Resolved",
          text: "Contract Resolved successfully",
          icon: "success",
          confirmButtonText: "OK",
        });
        initialiseData();
      })
      .catch((err:any) => {
        console.log("delte failed", err);
        Swal.fire({
          title: "Contact Resolved",
          text: err.response.body.error_description,
          icon: "error",
          confirmButtonText: "Cancel",
        });
      });
  };

  const handleDelete = (id:string) => {
    console.log("deleted");

    const data = {
      language: "ENGLISH",
      type: "DELETE",
      _id: id,
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result:any) => {
      if (result.isConfirmed) {
        try {
          henceforthApi.ContactUs.deleteContact(data)
            .then((res:any) => {
              console.log(res, "Deleted succesfully");

              Swal.fire({
                title: "Contact Deleted",
                text: "Contract deletd successfully",
                icon: "success",
                confirmButtonText: "OK",
              });
              initialiseData();
            })
            .catch((err:any) => {
              console.log("delte failed", err);
              Swal.fire({
                title: "Contact Delete Failed",
                text: err.response.body.error_description,
                icon: "error",
                confirmButtonText: "Cancel",
              });
            });
        } catch (error:any) {
          
        }
      }
    });
  };

  return (
    <div className="ibox animated fadeInRight">
      <div className="ibox-title">
        <h5>Users Reports</h5>
      </div>
      <div className="ibox-content">
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Sr</th>
                <th>Reported By</th>
                <th>Reported to</th>
                <th>Report Reason</th>
                {/* <th>Action</th> */}
              </tr>
            </thead>
            <tbody>
              {state.data.map((res:any, index:number) => {
                return (
                  <tr
                    key={res._id}
                    onMouseOver={() => setReadContent(res.reason)}
                  >
                    <td>{index + 1}</td>
                    <td>
                      <Link
                        to={`/${
                          res.reported_by.account_type == "CREATER"
                            ? "creator"
                            : "user"
                        }/${res.reported_by._id}`}
                      >
                        {res.reported_by.user_name}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/${
                          res.user_id.account_type == "CREATER"
                            ? "creator"
                            : "user"
                        }/${res.user_id._id}`}
                      >
                        {res.user_id ? res.user_id.user_name : "not found"}
                      </Link>
                    </td>
                    <td
                      data-bs-toggle="modal"
                      data-bs-target="#readContentModal"
                    >
                      {res.reason ? res.reason : "Not found"}
                    </td>
                    {/* <td><Link to={`/nft/${res.nft_id._id}`}> View</Link></td> */}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <PaginationLayout
          count={state.total_count}
          data={state.data}
          page={Number(match.params.page)}
          limit={10}
          loading={false}
          onPageChange={(val) => onChangePagination(val)}
        />
      </div>

      <div
        className="modal fade"
        id="readContentModal"
        tabIndex={-1}
        aria-labelledby="readContentModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p className="text-break">{readContent}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
