import React from 'react';
import PaginationLayout from "../components/PaginationLayout";
import { GlobalContext } from "../context/Provider";
import { useContext, useEffect, useState } from "react";
import henceforthApi from "../utils/henceforthApi";


function UserVehicles({ _id }: any) {
  const [booking, setBooking] = useState([])
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    data: {
      data: [],
      total_count: 0
    }
  })
  let limit = 10;
  const { authState } = useContext(GlobalContext);
  if(authState.access_token){henceforthApi.setToken(authState.access_token);}
  const onChangePagination = (newVal: number) => {
    setPage(Number(newVal))
  };
  const bookingDetails = async () => {
    const bookDetail = await henceforthApi.CarListing.pagination(_id)
    setState(bookDetail)

    console.log("booking details are", bookDetail)
  }

  useEffect(() => {
    bookingDetails()
  }, [page])
  return (
    <div className='row'>
      <div className="ibox">
        <div className="ibox-title">
          <h5>User Vehicle</h5>
        </div>
        <div className="ibox-content">
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Sr No</th>
                  <th>Car Name</th>
                  <th>Model</th>
                  <th>Type</th>
                  <th>Color</th>
                  <th>Model Name</th>
                </tr>
              </thead>
              {state.data?.total_count !== 0 ?
                <tbody>
                  {state.data.data?.map((res: any, index: number) => {

                    return (
                      <tr>
                        <td>
                          <td>
                            {page === 0
                              ? index + 1
                              : (page - 1) * limit + (index + 1)}
                            .
                          </td>
                        </td>
                        <td>
                          <div className="text-nowrap">
                            <img
                              src={
                                res?.image
                                  ? `${henceforthApi.API_FILE_ROOT_ORIGINAL}${res?.image}`
                                  : ""
                              }
                              className="me-2 imageSize"
                              alt=""
                            />
                            {res.vehicle_brand_name ? res.vehicle_brand_name : "Not available"}
                          </div>
                        </td>
                        <td>
                          {res.model_of_car ? res.model_of_car : "Not available"}
                        </td>
                        <td>
                          {res.vehicle_type_name ? res.vehicle_type_name : "Not available"}
                        </td>
                        <td>
                          {res.vehicle_color_name ? res.vehicle_color_name : "Not available"}
                        </td>
                        <td>
                          {res.vehicle_model_name ? res.vehicle_model_name : "Not available"}
                        </td>
                        <td>

                        </td>
                      </tr>
                    );
                  })}
                </tbody> :
                <tbody>
                  <td colSpan={6}>
                  <div className="row mt-4"><div className="col-12 text-center"><b className="text-center ms-5">Sorry ! no data to display</b></div></div>

                  </td>
                </tbody>
              }
            </table>
            <PaginationLayout
              count={state.data.total_count}
              data={state.data.data}
              page={page}
              limit={Number(limit)}
              loading={loading}
              onPageChange={(val) => onChangePagination(val)}
            />
            <div className="view-more mb-2">
              <div className="d-flex align-items-center justify-content-center">

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserVehicles
