import { Link, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import React from "react";

const Breadcrumb = ({ pathname }: any) => {
  let pathNameArr = String(pathname).split("/");
  const location = useLocation()

  let name = ""
  if (location?.pathname?.includes("policies")) {
    name = pathNameArr[2]
  }else{
    name = pathNameArr[1];
  }

  return (
    <div className="row wrapper border-bottom white-bg page-heading">
      <div className="col-lg-9">
        <h2 className="text-capitalize">{name?.includes("faqs") ? name?.toUpperCase()?.slice(0, 3) + "s" : name ? name.split('-')?.join(' ') : 'Profile'}</h2>
        <ol className="breadcrumb text-capitalize">
          {/* <Link to={location.pathname}>{pathname}</Link> */}
          {/* <Link to={newParams.get('screen') == '/users/1' ? 'users' : newParams.get('screen') == 'creators' ? '/creators/1' : '#'}></Link> */}
          {/* <li className="breadcrumb-item">
            <a href="index.html">Home</a>
          </li>
          <li className="breadcrumb-item">{from}</li>
          <li className="breadcrumb-item active">
            <strong> Search Page</strong>
          </li> */}
        </ol>
      </div>
    </div>
  );
};

export default Breadcrumb;
