import React from "react";

const EmailVariables = () => {
  return (
    <div className="ibox-content h-100">
      <h3 className="mb-3">Variables</h3>
      <div className="form-group">
        <h4 className="text-muted">User</h4>
        <button className="key-btn-bordered px-3 py-2 me-2 mb-2">first_name</button>
        <button className="key-btn-bordered px-3 py-2 me-2 mb-2">email</button>
        <button className="key-btn-bordered px-3 py-2 me-2 mb-2">password</button>
      </div>
    </div>
  );
};

export default EmailVariables;
