// import React from 'react'
import React, { useState, useEffect, useContext } from 'react';
import { Link, useMatch, useNavigate, useParams, useLocation } from 'react-router-dom'
import henceforthApi from '../utils/henceforthApi';
import { GlobalContext } from "../context/Provider";
import moment from 'moment';
import { toast } from "react-toastify";
import Swal from 'sweetalert2';
import PaginationLayout from "../components/PaginationLayout";
import profile_placeholder from "../assets/images/profile_placeholder.png";
interface inerRepotedto {
  name: string
}

interface inerRepotedBy {
  name: string
  profile_pic: string
}
interface ridereport {
  _id: string
  report_by: inerRepotedBy
  report_to: inerRepotedto
  is_resolved: boolean
  message: string
}
function ReportRide() {
  let match = useMatch("/report-user/:page")
  let location = useLocation()
  const [readContent, setReadContent] = useState("");

  const { authState, handleFilter, handlePagination, handleError } = useContext(GlobalContext);
  const params: any = useParams();
  const newParam = new URLSearchParams(location.search);

  const [totalCount, setTotalCount] = useState<number>(0);
  if(authState.access_token){henceforthApi.setToken(authState.access_token);}
  let limit = 10
  const FIXED_COUPONS = "FIXED";
  const PERCENTAGE_COUPONS = "PERCENTAGE"
  const [report, setReport] = useState<Array<ridereport>>([])
  var query = new URLSearchParams(location.search);
  const [loading, setLoading] = useState(false);

  const reportList = async () => {
    try {
      setLoading(true);
      let q = query.toString()
      let apiRes = await henceforthApi.Report.ride(newParam.toString(), q,
        limit, Number(params.page) - 1
      )
      setReport(apiRes.data.data)
      setTotalCount(apiRes.data.data.total_count)
      setLoading(false);
    }
    catch (error: any) {
      setLoading(false);
      setReport([]);
      setTotalCount(0);
      handleError(error)
    }
  }
  console.log(report)
  const handleDelete = (id: string) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result: any) => {
      if (result.isConfirmed) {
        try {
          henceforthApi.Report.del(id).then((res: any) => {
            Swal.fire({
              title: "Report Deleted",
              text: "Report deleted successfully",
              icon: "success",
              confirmButtonText: "OK",
            })
            reportList()
          }).catch((err: any) => {
            console.log("delte failed", err);
            Swal.fire({
              title: "Report Delete Failed",
              text: err.response.body.error_description,
              icon: "error",
              confirmButtonText: "Cancel",
            });
          });
        }
        catch {
          console.log("error")
        }
      }
    })

  }

  const filters = [
    { name: "Resolved", value: "1" },
    { name: "Pending", value: "0" },
  ]
  const filters1 = [
    { name: "Driver", value: "1" },
    { name: "Passenger", value: "2" },
  ]


  const handleResolve = (id: string) => {
    const data = {

      is_resolved: true,
      _id: id,
    };
    console.log("resolved");

    henceforthApi.Report.resolveRides(data)
      .then((res: any) => {
        console.log(res, "Resolved succesfully");
        Swal.fire({
          title: "Report Resolved",
          text: "Report Resolved successfully",
          icon: "success",
          confirmButtonText: "OK",
        });

      })
      .catch((err: any) => {
        console.log("delte failed", err);
        Swal.fire({
          title: "Report Resolved",
          text: err.response.body.error_description,
          icon: "error",
          confirmButtonText: "Cancel",
        });
      });
  };
  useEffect(() => {
    reportList()
  }, [params.page, newParam.get("search"), query.get("is_resolved"), query.get("reported_by")])
  return (
    <>
      <div className='ibox animated fadeInRight'>
        <div className="ibox-title d-flex align-items-center justify-content-between">
          <h5>List of all reported user</h5>

          <div className="d-flex align-items-center justify-content-end">
            <div className="dropdown theme me-3">
              <button className="btn dashboard-button pe-4 ps-4 dropdown-toggle shadow-none text-white" id="sortDropdown" data-bs-toggle="dropdown"
                aria-expanded="false">{(filters1.filter(res => res.value == query.get("reported_by"))).length ? (filters1.filter(res => res.value === query.get("reported_by")))[0].name
                  : 'All Reports'}<i className="fas fa-chevron-down bi-chevron-down ms-2 unset-icon" style={{ fontSize: "12px" }}></i></button>
              <ul className="dropdown-menu share-icons" aria-labelledby="sortDropdown">

                {filters1.map(res => {
                  // selected
                  return <li key={res.value} className="cursor-pointer"><a className="dropdown-item" onClick={() => handleFilter("reported_by", res.value)}>{res.name}</a></li>
                })}
              </ul>
            </div>
            <div className="dropdown theme me-3">
              <button className="btn dashboard-button pe-4 ps-4 dropdown-toggle shadow-none text-white" id="sortDropdown" data-bs-toggle="dropdown"
                aria-expanded="false">{(filters.filter(res => res.value == query.get("is_resolved"))).length ? (filters.filter(res => res.value === query.get("is_resolved")))[0]?.name
                  : 'Status'}<i className="fas fa-chevron-down bi-chevron-down ms-2 unset-icon" style={{ fontSize: "12px" }}></i></button>
              <ul className="dropdown-menu share-icons" aria-labelledby="sortDropdown">
                {query.has("is_resolved") &&
                  <li className="cursor-pointer"><a className="dropdown-item" onClick={() => handleFilter("is_resolved", "")}>All</a></li>
                }
                {filters.map(res => {
                  return <li key={res.value} className="cursor-pointer"><a className="dropdown-item" onClick={() => handleFilter("is_resolved", res.value)}>{res.name}</a></li>
                })}

              </ul>
            </div>

          </div>
        </div>

        {/* <hr className='w-100' /> */}
        <div className='ibox-content'>
          <form
            onSubmit={(e: any) => {
              e.preventDefault();
            }}
          >
            <div className="input-group form-group">
              <input
                name="search"
                onChange={(e: any) => {
                  handleFilter(e.target.name, e.target.value);
                }}
                placeholder="Search by User"
                type="text"
                className="form-control form-control-sm"
              />{" "}
            </div>
          </form>
          <div className="table-responsive mt-4">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Sr</th>
                  <th>Reported By</th>
                  <th>Reported To</th>
                  <th>Report Reason</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              {!loading &&
                <tbody>
                  {Array.isArray(report) && report?.length > 0 ? report?.map((res: any, index: number) => {
                    return (
                      <tr key={res._id}>
                        <td>
                          {params.page === "0"
                            ? index + 1
                            : (params.page - 1) * limit + (index + 1)}.
                        </td>
                        <td>   <div className="text-nowrap">
                          <img
                            src={
                              res?.report_by?.profile_pic ? res?.report_by?.profile_pic.startsWith("https") ? res?.report_by?.profile_pic : henceforthApi.FILES.imageOriginal(res?.report_by?.profile_pic) : profile_placeholder}
                            className="me-2 imageSize"
                            alt=""
                          />
                          {res?.report_by?.name ? `${res?.report_by?.name} ` : `Not Available`}
                        </div></td>
                        <td>  {res?.report_to?.name ? `${res?.report_to?.name} ` : `Not Available`}</td>
                        {/* <td>  {res?.message ? `${res?.message} ` : `Not Available`}</td> */}
                        <td>{res.message.length < 40 ? res.message.slice(0, 40) : res.message.slice(0, 40) + "..,"}
                          {res.message.length < 40 ? ("") : (
                            <button
                              className="readmore btn btn-xs "
                              data-bs-toggle="modal"
                              data-bs-target="#readContentModal"
                              onMouseOver={() => setReadContent(res?.message)}
                            >
                              Read more
                            </button>
                          )}
                        </td>
                        <td>  <div className="btn-group">
                          <button
                            disabled={res.is_resolved ? true : false}
                            onClick={() => handleResolve(res._id)}
                            className="btn btn-default btn-xs"
                          >
                            <i
                              className={
                                !res.is_resolved
                                  ? `fas fa-info-circle me-1`
                                  : `fas fa-check-circle me-1`
                              }
                            ></i>
                            {!res.is_resolved ? "Pending" : "Resolved"}
                          </button>
                        </div></td>
                        <td>
                          <div className="btn-group">
                            <button
                              onClick={() => handleDelete(res._id)}
                              className="btn-white btn btn-xs me-2"
                            >
                              <i className="fas fa-trash-alt me-1"></i>Delete
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  }) : <tr> <td colSpan={12} className="text-center">
                    Sorry! no data to display
                  </td> </tr>}
                </tbody>}
            </table>


            <PaginationLayout
              count={totalCount}
              data={report}
              page={Number(params.page)}
              limit={Number(limit)}
              loading={loading}
              onPageChange={(val) => handlePagination(val)}
            />
          </div>
          <div
            className="modal fade"
            id="readContentModal"
            tabIndex={-1}
            aria-labelledby="readContentModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="m-0">Report</h4>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <p className="text-break">{readContent}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  )
}

export default ReportRide;
