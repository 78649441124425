import React from 'react'
import PaginationLayout from "../components/PaginationLayout";
import { Link, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import henceforthApi from "../utils/henceforthApi";
import { GlobalContext } from "../context/Provider";
import { useMatch } from "react-router-dom";
import moment from "moment";
import DownloadfileModal from '../components/common/downloadfileModal';
import henceofrthEnums from '../utils/henceofrthEnums';
interface driver {
  name: string
  email: string
}
interface list {
  driver_detail: driver
  date: string,
  status: string,
  address_from: string,
  address_to: string
  price: number;
  _id: string
}
function RideListing() {
  const location = useLocation();
  let limit = 10;
  const { authState, handlePagination, handleFilter, handleError } = useContext(GlobalContext);
  const params: any = useParams();
  const newParam = new URLSearchParams(location.search);
  const [loading, setLoading] = useState(false);
  const [pageData, setPageData] = useState<Array<list>>([]);
  const [totalCount, setTotalCount] = useState(0);

  if(authState.access_token){henceforthApi.setToken(authState.access_token);}
  const match = useMatch("/created-rides/:page")

  var query = new URLSearchParams(location.search);
  const filters = [
    { name: "COMPLETED", value: "COMPLETED" },
    { name: "PENDING", value: "PENDING" },
    { name: "ONGOING", value: "ONGOING" },
    { name: "CONFIRMED", value: "CONFIRMED" },
    { name: "DECLINED", value: "DECLINED" },
    { name: "CANCELLED", value: "CANCELLED" },
  ]

  const downloadCsv = async (start_date: string, end_date: string) => {
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    try {
      let apiRes = await henceforthApi.Rides.export(
        moment(start_date).format("DD/MM/YYYY"),
        moment(end_date).format("DD/MM/YYYY"),
      );
      let data = apiRes.data.publish_rides;
      console.log(data);

      const rows = [
        [
          "Sr No.",
          "Name",
          "Email",
          "Date",
          "Price",
          "Status",
          "Pick up Location",
          "Drop off Location",
        ],
      ];
      if (Array.isArray(data)) {
        data.map((res: any, index: number) => {
          rows.push([
            index + 1,
            res.driver_detail?.name,
            res.driver_detail?.email,
            moment(Number(res.date)).format("MMM DD YYYY"),
            res.price,
            res.status,
            res.address_from.split(",").join(""),
            res.address_to.split(",").join(""),
          ]);
        });
      }
      console.log(rows);
      let csvContent =
        "data:text/csv;charset=utf-8," +
        rows.map((e) => e.join(",")).join("\n");
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `user_${moment().valueOf()}.csv`);
      document.body.appendChild(link); // Required for FF

      link.click(); // This will download the data file named "my_data.csv".
      let closeModal = document.getElementById("closeModal");
      if (closeModal) {
        closeModal.click();
      }
    } catch (error: any) { }
  };

  useEffect(() => {
    let q = query.toString()
    const init = async () => {
      setLoading(true);
      let searchData = newParam.has("search") ? newParam.get("search") : null;
      try {
        let apiRes = await henceforthApi.Rides.getListing(
          newParam.toString(),
          q,
          Number(params.page) - 1,
          limit
        );
        setTotalCount(apiRes.data.count);
        setPageData(apiRes.data.publish_rides);
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        setPageData([]);
        setTotalCount(0);
        handleError(error)
      }
    };
    init();
  }, [params.page, newParam.get("search"), query.get("status")]);

  return (
    <>
      <div className="ibox animated fadeInRight">
        <div className="ibox-title d-flex align-items-center justify-content-between">
          <h5>List of all Rides</h5>
          <div className="d-flex align-items-center justify-content-end ">
            <div className="dropdown theme me-3">
              <button className="btn dashboard-button pe-4 ps-4 dropdown-toggle shadow-none text-white" id="sortDropdown" data-bs-toggle="dropdown"
                aria-expanded="false">{(filters.filter(res => res.value == query.get("status"))).length ? (filters.filter(res => res.value === query.get("status")))[0].name
                  : 'Sort By'}<i className="fas fa-chevron-down bi-chevron-down ms-2 unset-icon" style={{ fontSize: "12px" }}></i></button>
              <ul className="dropdown-menu share-icons" aria-labelledby="sortDropdown">
                {query.has("status") &&
                  <li className="cursor-pointer"><a className="dropdown-item" onClick={() => handleFilter("status", "")}>ALL</a></li>
                }
                {filters.map(res => {
                  return <li key={res.value} className="cursor-pointer"><a className="dropdown-item" onClick={() => handleFilter("status", res.value)}>{res.name}</a></li>
                })}
              </ul>
            </div>
            <button
              className="btn btn-primary-button mb-1"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              Export
            </button>

          </div>
        </div>
        <div className="ibox-content">
          <form
            onSubmit={(e: any) => {
              e.preventDefault();
            }}
          >
            <div className="input-group form-group">
              <input
                name="search"
                onChange={(e: any) => {
                  handleFilter(e.target.name, e.target.value);
                }}
                placeholder="Search by Name "
                type="text"
                className="form-control form-control-sm"
              />{" "}
            </div>
          </form>
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Sr No.</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Date</th>
                  <th>Pick up Location</th>
                  <th>Drop off Location</th>
                  <th>Price</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              {
                !loading &&
                <tbody>
                  {Array.isArray(pageData) && pageData.length > 0 ? pageData.map((res: any, index: number) => {
                    return (
                      <tr
                        key={res._id}>
                        <td>
                          {params.page === "0"
                            ? index + 1
                            : (params.page - 1) * limit + (index + 1)}.

                        </td>
                        <td>  {res?.driver_detail?.name ? `${res?.driver_detail?.name} ` : `Not Available`}</td>
                        <td> {res?.driver_detail?.email ? res?.driver_detail?.email : "Not Avialable"}</td>
                        <td>{moment(Number(res?.date)).utc(true).format("MMM DD YYYY")}</td>
                        <td title={res?.address_from}>{res?.address_from.length > 2 ? res?.address_from.slice(0, 25) : ''}</td>
                        <td title={res?.address_to}>{res?.address_to.length > 2 ? res?.address_to.slice(0, 25) : "Not available"}</td>
                        <td>{res?.price ? res?.currency == henceofrthEnums.CurrencyType.NGN ? `₦ ${res?.price}` : `$ ${res?.price}` : "Not available"}</td>
                        <td>{res?.status ? res?.status : 'Not Available'}</td>
                        <td>
                          <div className="d-flex">
                            <Link
                              to={`/ride-details/${res?._id}`}
                              className="btn btn-xs btn-white me-2"
                            >
                              <i className="fas fa-eye me-1"></i>
                              View
                            </Link>
                          </div>
                        </td>
                      </tr>
                    );
                  }) : <tr> <td colSpan={12} className="text-center">
                    Sorry! no data to display
                  </td> </tr>}
                </tbody>
              }

            </table>
          </div>
          <PaginationLayout
            count={totalCount}
            data={pageData}
            page={Number(params.page)}
            limit={Number(limit)}
            loading={loading}
            onPageChange={(val) => handlePagination(val)}
          />
        </div>
      </div>
      <DownloadfileModal data={downloadCsv} />

    </>
  )
}

export default RideListing
