import { Link, useMatch } from "react-router-dom";
import profile_placeholder from "../assets/images/profile_placeholder.png"
import React, { useState, useEffect, useContext } from "react";
import henceforthApi from "../utils/henceforthApi";
import moment from "moment";
import { GlobalContext } from "../context/Provider";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import MaskImage from "../components/common/MaskImage";
interface driver {
  _id: string,
  name: string
  total_reviews: number,
  email: string,
  average_ratings: number,

}
interface user {
  _id: string,
  email: string,
  total_reviews: number
  profile_pic: string,
  average_ratings: number,
  name: string
}
interface booking {
  account_status: string,
  account_type: string,
  admin_verified: boolean,
  age_verified: boolean,
  bio: string,
  cover_img: string,
  created_at: string,
  email: string,
  email_verified: boolean,
  name: string,
  date: string,
  is_blocked: boolean,
  is_deleted: boolean,
  last_name: string,
  city_from: string,
  last_seen?: null,
  profile_pic: string,
  social_token: string,
  licence_id: string,
  vehicle_brand_name: string,
  phone_no: string,
  drop_off_time: string,
  gender: string,
  front_doc: any,
  model_of_car: string
  social_type: string,
  total_coins: number,
  updated_at: null,
  date_of_birth: string
  user_name: string,
  user_status: number,
  _id: string,
  recordings: any,
  pickup_time: string,
  vehicle_color_name: string,
  driver_detail: driver,
  satus: string,
  city_to: string
  licence_plate_number: string,
  user_detail: user
}
type managedata = {
  type: string,
  _id: string,
  is_blocked: boolean,
  is_deleted: boolean,
  language: string,
  account_status: string,
}

const BookingDetail = () => {
  const match: any = useMatch("/booking-details/:id");
  const { authState, handleError } = useContext(GlobalContext);
  const [recording, setRecording] = useState([])
  const [userDetails, setUserDetails] = useState({
    account_status: "",
    account_type: "",
    admin_verified: false,
    age_verified: false,
    bio: "",
    cover_img: "",
    city_to: "",
    created_at: "",
    recordings: "",
    vehicle_brand_name: "",
    model_of_car: "",
    city_from: "",
    driver_detail: {
      _id: "",
    },
    email: "",
    // city_from:"",
    date: "",
    email_verified: false,
    name: "",
    pickup_time: "",
    satus: "",
    vehicle_color_name: "",
    is_blocked: false,
    is_deleted: false,
    last_name: "",

    last_seen: null,
    profile_pic: "",
    social_token: "",
    licence_plate_number: "",
    social_type: "",
    drop_off_time: "",
    total_coins: 0,
    updated_at: null,
    user_name: "",
    user_detail: {
      _id: '',
      email: ""
    },
    user_status: 0,
    _id: "",
  } as booking);
  if (authState.access_token) { henceforthApi.setToken(authState.access_token); }


  const [manageUsers, setManageUsers] = useState({
    type: "",
    _id: "",
    is_blocked: false,
    is_deleted: false,
    admin_verified: false,
    language: "ENGLISH",
    account_status: "",
  });
  const getBookingDetails = async () => {
    try {
      const userDetail = await henceforthApi.BookingListing.getBookingDetails(match.params.id);
      console.log("user details are", userDetail);
      setUserDetails(userDetail.data[0]);
      setRecording(userDetail.data[0].recordings[0])
      console.log(userDetail.data[0].recordings)
    } catch (error) {
      handleError(error)
    }
  };
  const handleActivate = async () => {
    let data = {
      type: "ACTIVATE/DEACTIVATE",
      _id: userDetails._id,
      is_blocked: userDetails.is_blocked,
      is_deleted: userDetails.is_deleted,
      language: "ENGLISH",
      account_status:
        userDetails.account_status == "ACTIVATED" ? `DEACTIVATED` : `ACTIVATED`,
    } as managedata;
    try {
      const manage_users = await henceforthApi.User.manageUsers(data);
      console.log("mmanage users", manage_users);
      setManageUsers(data as managedata | any);
      {
        userDetails.account_status == "DEACTIVATED"
          ? Swal.fire({
            title: "Activated",
            text: "Account Activated Successfully",
            icon: "success",
            confirmButtonText: "OK",
          })
          : Swal.fire({
            title: "Deactivated",
            text: "Account Deactivated Successfully",
            icon: "success",
            confirmButtonText: "OK",
          });
      }
    } catch (error: any) {
      console.log(error, "error is ");
    }
  };
  const handleBlocking = async () => {
    let data = {
      type: "BLOCK/DELETE",
      _id: userDetails._id,
      is_blocked: !Boolean(userDetails.is_blocked),
      is_deleted: userDetails.is_deleted,
      language: "ENGLISH",
      account_status: userDetails.account_status,
    } as any;
    try {
      const manage_users = await henceforthApi.User.manageUsers(data);
      console.log("mmanage users", manage_users);
      setManageUsers(data);
      {
        !userDetails.is_blocked
          ? Swal.fire({
            title: "User Blocked",
            text: "User Blocked  successfully",
            icon: "success",
            confirmButtonText: "OK",
          })
          : Swal.fire({
            title: "User Unblocked",
            text: "User Unblocked  successfully",
            icon: "success",
            confirmButtonText: "OK",
          });
      }
    } catch (error: any) {
      console.log(error, "error is ");
      Swal.fire({
        title: "Error!",
        text: error.response.body.error_description,
        icon: "error",
        confirmButtonText: "Cancel",
      });
    }
  };
  const handleDelete = async (creatorId: string) => {
    let data = {
      type: "BLOCK/DELETE",
      _id: userDetails._id,
      is_blocked: userDetails.is_blocked,
      is_deleted: true,
      language: "ENGLISH",
      account_status: userDetails.account_status,
    };
    try {
      const manage_users = await henceforthApi.User.manageUsers(data);
      Swal.fire({
        title: "User Deleted",
        text: "User Deleted  successfully",
        icon: "success",
        confirmButtonText: "OK",
      });
      window.history.back();
      console.log("mmanage users", manage_users);
      setManageUsers(manage_users);
    } catch (error: any) {
      console.log(error, "error is ");
      Swal.fire({
        title: "Error!",
        text: error.response.body.error_description,
        icon: "error",
        confirmButtonText: "Cancel",
      });
    }
  };
  const copyText = (id: string) => {
    if (id) {
      navigator?.clipboard?.writeText(id)
      toast.success("Id copied")
    }
  }
  useEffect(() => {
    getBookingDetails();
  }, [manageUsers]);

  useEffect(() => {
    // scrollToTop();
  }, []);
  return (
    <>
      <div className="row animated fadeInRight">
        <div className="col-md-6">
          <div className="ibox">
            <div className="ibox-title">
              <h5>Booking Details</h5>
            </div>
            <div className="ibox-content">
              <div className="upload-image m-auto position-relative">
                <div className="text-center">
                  <img
                    src={
                      userDetails?.user_detail?.profile_pic !== 'null' && userDetails?.user_detail?.profile_pic !== null
                        ? `${henceforthApi.API_FILE_ROOT_ORIGINAL}${userDetails?.user_detail?.profile_pic}`
                        : profile_placeholder
                    }
                    alt="ProfileImage"
                    className="detail-img rounded-circle"
                  />
                </div>
                <MaskImage image={userDetails?.user_detail?.profile_pic} key={userDetails?.user_detail?.profile_pic} />
              </div>
              <div className="mt-4">
                <p className="text-black mb-1 user-text">
                  <b>Name :</b>
                  <span className="text-muted-userD ms-1">
                    {userDetails?.user_detail?.name ? ` ${userDetails?.user_detail.name} ` : `Not Available`}
                  </span>
                </p>
                <p className="text-black mb-1 word-break-faq user-text" >
                  <b>ID :{" "}</b>
                  <span className="text-muted-userD ms-1" id="myInput">
                    {userDetails?.user_detail?._id ? `${userDetails?.user_detail._id}` : "not available"}<i className="bi bi-clipboard ms-2 cursor-pointer" onClick={() => copyText(userDetails?.user_detail?._id)} ></i>
                  </span>
                </p>
                <p className="text-black mb-1 word-break-faq user-text">
                  <b>Pick up Location :{" "}</b>
                  <span className="text-muted-userD ms-1">
                    {userDetails?.city_from ? userDetails?.city_from : "Not available"}
                  </span>
                </p>
                <p className="text-black mb-1 word-break-faq user-text">
                  <b>Drop off Location :</b>
                  <span className="text-muted-userD ms-1">
                    {userDetails?.city_to ? userDetails?.city_to : "Not available"}
                  </span>
                </p>
                <p className="text-black mb-1 word-break-faq user-text">
                  <b>pickup date :</b>
                  <span className="text-muted-userD ms-1">
                    {userDetails?.date ? moment(Number(userDetails?.date)).format("MMM DD YYYY") : "Not available"}
                  </span>
                </p>
                <p className="text-black mb-1 word-break-faq user-text">
                  <b>pickup time :</b>
                  <span className="text-muted-userD ms-1">
                    {userDetails?.pickup_time ? userDetails?.pickup_time : "Not available"}
                  </span>
                </p>
                <p className="text-black mb-1 word-break-faq user-text">
                  <b> Drop-Off time :</b>
                  <span className="text-muted-userD ms-1">
                    {userDetails?.drop_off_time ? userDetails?.drop_off_time : "Not available"}
                  </span>
                </p>
                <p className="text-black mb-1 word-break-faq user-text">
                  <b>Reviews :</b>
                  <span className="text-muted-userD ms-1">
                    {userDetails?.user_detail?.total_reviews}
                  </span>
                </p>
                <p className="text-black mb-1 word-break-faq user-text">
                  <b>Rating :</b>
                  <span className="text-muted-userD ms-1">
                    {userDetails?.user_detail?.average_ratings}
                  </span>
                </p>
                <p className="text-black mb-1 word-break-faq user-text">
                  <b>Email :</b>
                  <span className="text-muted-userD ms-1">
                    {userDetails?.user_detail?.email ? userDetails?.user_detail?.email : "not available"}
                  </span>
                </p>
                <p className="text-black mb-1 user-text">
                  <b>Status :</b>
                  <span className="text-muted-userD ms-1">
                    {userDetails?.satus}
                  </span>
                </p>
                <hr />
                {/* Car detail  */}
                <p className="mt-3">Car detail</p>
                <p className="text-black mb-1 user-text">
                  <b>Name :</b>
                  <span className="text-muted-userD ms-1">
                    {userDetails?.vehicle_brand_name ? ` ${userDetails?.vehicle_brand_name}` : `Not Available`}
                  </span>
                </p>
                <p className="text-black mb-1 word-break-faq user-text" >
                  <b>Number :{" "}</b>
                  <span className="text-muted-userD ms-1" id="myInput">
                    {userDetails?.licence_plate_number ? userDetails?.licence_plate_number : "Not available"}
                  </span>
                </p>
                <p className="text-black mb-1 word-break-faq user-text">
                  <b>Model :</b>
                  <span className="text-muted-userD ms-1">
                    {userDetails?.model_of_car ? userDetails?.model_of_car : "not available"}
                  </span>
                </p>
                <p className="text-black mb-1 word-break-faq user-text">
                  <b>Color :</b>
                  <span className="text-muted-userD ms-1">
                    {userDetails?.vehicle_color_name ? userDetails?.vehicle_color_name : "not available"}
                  </span>
                </p>
                <hr />
                {/* Driver Detail  */}
                <p className="mt-3">Driver detail</p>
                <p className="text-black mb-1 user-text">
                  <b>Name :</b>
                  <span className="text-muted-userD ms-1">
                    {userDetails?.driver_detail?.name
                      ? ` ${userDetails?.driver_detail.name} `
                      : `Not Available`}
                  </span>
                </p>
                <p className="text-black mb-1 word-break-faq user-text" >
                  <b>ID :{" "}</b>
                  <span className="text-muted-userD ms-1" id="myInput">
                    {userDetails?.driver_detail?._id ? `${userDetails?.driver_detail._id}` : "not available"}
                    <i className="bi bi-clipboard ms-2 cursor-pointer" onClick={() => { navigator?.clipboard?.writeText(userDetails?.driver_detail?._id); toast.success("Id copied") }} ></i>
                  </span>
                </p>
                <p className="text-black mb-1 word-break-faq user-text">
                  <b>Email :{" "}</b>
                  <span className="text-muted-userD ms-1">
                    {userDetails?.driver_detail?.email ? userDetails?.driver_detail?.email : "Not available"}
                  </span>
                </p>
                <p className="text-black mb-1 word-break-faq user-text">
                  <b>Reviews :{" "}</b>
                  <span className="text-muted-userD ms-1">
                    {userDetails?.driver_detail?.total_reviews}
                  </span>
                </p>
                <p className="text-black mb-1 word-break-faq user-text">
                  <b>Rating :{" "}</b>
                  <span className="text-muted-userD ms-1">
                    {userDetails?.driver_detail?.average_ratings}
                  </span>
                </p>

                <hr />
                <div className="mt-4">
                  {/* <button
                    type="button"
                    onClick={handleBlocking}
                    className="btn-default btn btn-sm me-2"
                  >
                    <i
                      className={
                        userDetails.is_blocked == false
                          ? `fas fa-unlock me-2`
                          : `fas fa-lock me-2`
                      }
                    ></i>
                    {userDetails.is_blocked == false ? `Block` : `Unblock`}
                  </button> */}
                  {/* <a href={`https://nftyit.com/profile?id=${userDetails._id}&token=${authState.access_token}`} target="_blank" className="btn-primary-button btn btn-sm me-2">
                                        <i className="fas fa-sign-in-alt me-2"></i>Login as User
                                    </a> */}
                </div>
                <div className="mt-3">
                  {/* <button
                    type="button"
                    onClick={handleActivate}
                    className="btn-default btn btn-sm me-2"
                  >
                    <i
                      className={
                        userDetails.account_status == "ACTIVATED"
                          ? `fas fa-unlock me-2`
                          : `fas fa-lock me-2`
                      }
                    ></i>
                    {userDetails.account_status == "ACTIVATED"
                      ? `Deactivate`
                      : `Activate`}
                  </button> */}
                  {/* <button
                    onClick={handleDelete}
                    disabled={userDetails.is_deleted ? true : false}
                    className="btn-outline-primary btn btn-sm me-2"
                  >
                    <i
                      className={
                        userDetails.is_deleted
                          ? `fa fa-ban me-2`
                          : "fas fa-trash-alt me-2"
                      }
                    ></i>
                    {userDetails.is_deleted ? `Deleted` : `Delete`}
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="ibox">
            <div className="ibox-title d-flex justify-content-between">
              <h5>Recordings</h5>
              <div className="d-flex">

              </div>
            </div>
            <div className="ibox-content">
              <div className="text-center">
                {Array.isArray(userDetails?.recordings) ? userDetails?.recordings?.map((item: any) => item.type === "AUDIO" ? <div> <audio controls src={`${henceforthApi?.API_FILE_ROOT_AUDIO}${item.url}`} > </audio ></div> :
                  <div className="" >
                    <video width="320" className="video-size mb-2 mt-2" height="240" src={`${henceforthApi?.API_FILE_ROOT_VIDEO}${item.url}`} controls >
                    </video>
                  </div>) : ""}
                <div>{userDetails?.recordings == "" ? "No Recording Found" : ""}
                </div>

              </div>

              {/* <video width="320" height="240" src={`${henceforthApi?.API_FILE_ROOT_VIDEO}${item.url}`} type={item.type} controls >
              </video> */}

            </div>
          </div>
        </div>

      </div>


    </>
  )
}

export default BookingDetail;