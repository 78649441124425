import React, { useState } from 'react'
import moment from 'moment';
import henceforthApi from '../../utils/henceforthApi';
import { Spinner } from '../BootstrapCompo';
function DownloadfileModal({ data }: any) {
    const [start_date, setStartDate] = useState(moment().subtract(10, 'days').toDate().getTime());
    const [end_date, setEndDate] = useState(moment().toDate().getTime());
    const download = async () => {
        await data(start_date, end_date)
    }


    return (
        <div
            className="modal fade"
            id="exampleModal"
            tabIndex={-1}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title" id="exampleModalLabel">
                            Download CSV
                        </h4>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            id="closeModal"
                        ></button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-6">
                                <label className="form-label">Date From</label>
                                <input
                                    type="date" max={moment().format('YYYY-MM-DD')}
                                    className="form-control"
                                    onChange={(e: any) => setStartDate(e.target.valueAsNumber)}
                                />
                            </div>
                            <div className="col-md-6">
                                <label className="form-label">Date To</label>
                                <input
                                    type="date"
                                    max={moment().format('YYYY-MM-DD')}
                                    className="form-control"
                                    onChange={(e: any) => setEndDate(e.target.valueAsNumber)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer ">
                        <button
                            type="button"
                            className="btn btn-primary-button"
                            onClick={download}
                        >
                            Export
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DownloadfileModal
