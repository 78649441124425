import React from 'react'
import { Link, useMatch } from "react-router-dom";
import profile_placeholder from "../assets/images/profile_placeholder.png";
import { useState, useEffect, useContext } from "react";
import henceforthApi from "../utils/henceforthApi";
import { GlobalContext } from "../context/Provider";
import Swal from "sweetalert2";
import moment from "moment";
import { ExploreCard, Activity, CollectionCard } from "../components/ExploreComp";
import { WALLET_SLICE_WALLET } from "../constants/actionTypes";
import Tiktok from "../assets/images/tiktok-brands.svg";
import { toast } from "react-toastify";
import MaskImage from '../components/common/MaskImage';
import henceofrthEnums from '../utils/henceofrthEnums';
interface rideDetail {
  seats_available: number
  price: number
  driver_name: string
  image: string
  address_to: string,
  time_taken: string
  address_from: string,
  date: string
  vehicle_brand_name: string
  pickup_time: string,
  currency: string
}
function RideDetails() {
  const match: any = useMatch("/ride-details/:id");
  const { authState, handleError } = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);

  const [userDetails, setUserDetails] = useState({
    account_status: "",
    account_type: "",
    admin_verified: false,
    age_verified: false,
    bio: "",
    cover_img: "",
    created_at: "",
    email: "",
    email_verified: false,
    name: "",
    is_blocked: false,
    is_deleted: false,
    last_name: "",
    last_seen: null,
    profile_pic: "",
    social_token: "",
    social_type: "",
    total_coins: 0,
    updated_at: null,
    user_name: "",
    user_status: 0,
    _id: "",
  });
  if (authState.access_token) { henceforthApi.setToken(authState.access_token); }
  const [userCar, setUserCar] = useState([])
  const [ridedetail, setRidedetail] = useState<rideDetail>()
  const [manageUsers, setManageUsers] = useState({
    type: "",
    _id: "",
    is_blocked: false,
    is_deleted: false,
    admin_verified: false,
    language: "ENGLISH",
    account_status: "",
  } as any);




  // const getUserDetails = async () => {
  //   const userDetail = await henceforthApi.User.getUserDetails(match.params.id);
  //   console.log("user details are", userDetail);
  //   setUserDetails(userDetail.data);

  // };
  // const getCarDetalils=async()=>{
  //   const carDetail=await henceforthApi.CarListing.getCarDetails(match.params.id)
  //   console.log("car details are",carDetail.data.data[0])
  //   setUserCar(carDetail.data.data)
  // }

  const getRideDetalils = async () => {
    try {
      const rideDetail = await henceforthApi.RidingDetails.getRideDetails(match.params.id)
      // setRiding(rideDetail.data.data)
      setRidedetail(rideDetail.data[0])
      console.log("ride details are", rideDetail.data[0])
    } catch (error) {
      handleError(error)
    }
  }
  const handleActivate = async () => {
    let data = {
      type: "ACTIVATE/DEACTIVATE",
      _id: userDetails._id,
      is_blocked: userDetails.is_blocked,
      is_deleted: userDetails.is_deleted,
      language: "ENGLISH",
      account_status:
        userDetails.account_status == "ACTIVATED" ? `DEACTIVATED` : `ACTIVATED`,
    };

    try {
      const manage_users = await henceforthApi.User.manageUsers(data);
      console.log("mmanage users", manage_users);
      setManageUsers(data);
      {
        userDetails.account_status == "DEACTIVATED"
          ? Swal.fire({
            title: "Activated",
            text: "Account Activated Successfully",
            icon: "success",
            confirmButtonText: "OK",
          })
          : Swal.fire({
            title: "Deactivated",
            text: "Account Deactivated Successfully",
            icon: "success",
            confirmButtonText: "OK",
          });
      }
    } catch (error: any) {
      console.log(error, "error is ");
    }
  };

  const handleBlocking = async () => {
    let data = {
      type: "BLOCK/DELETE",
      _id: userDetails._id,
      is_blocked: !Boolean(userDetails.is_blocked),
      is_deleted: userDetails.is_deleted,
      language: "ENGLISH",
      account_status: userDetails.account_status,
    };

    try {
      const manage_users = await henceforthApi.User.manageUsers(data);
      console.log("mmanage users", manage_users);
      setManageUsers(data);
      {
        !userDetails.is_blocked
          ? Swal.fire({
            title: "User Blocked",
            text: "User Blocked  successfully",
            icon: "success",
            confirmButtonText: "OK",
          })
          : Swal.fire({
            title: "User Unblocked",
            text: "User Unblocked  successfully",
            icon: "success",
            confirmButtonText: "OK",
          });
      }
    } catch (error: any) {
      console.log(error, "error is ");
      Swal.fire({
        title: "Error!",
        text: error.response.body.error_description,
        icon: "error",
        confirmButtonText: "Cancel",
      });
    }
  };


  // Swal.fire({
  //   title: "Are you sure?",
  //   text: "You won't be able to revert this!",
  //   icon: "warning",
  //   showCancelButton: true,
  //   confirmButtonColor: "#3085d6",
  //   cancelButtonColor: "#d33",
  //   confirmButtonText: "Yes, delete it!",
  // }).then(async (result:any) => {
  //   if (result.isConfirmed) {
  //     try {
  //       henceforthApi.Faq.deleteFaq(id)
  //         .then((res:any) => {
  //           console.log(res, "response");
  //           Swal.fire({
  //             title: "Faq Deleted",
  //             text: res.data.message,
  //             icon: "success",
  //             confirmButtonText: "OK",
  //           });
  //           window.history.back();
  //         })
  //         .catch((err:any) => {
  //           console.log(err, "ERROR");

  //           Swal.fire({
  //             title: "Error!",
  //             text: err,
  //             icon: "error",
  //             confirmButtonText: "Cancel",
  //           });
  //         });
  //     } catch (error:any) { }
  //   }
  // });




  const handleDelete = async (creatorId: any) => {
    let data = {
      type: "BLOCK/DELETE",
      _id: userDetails._id,
      is_blocked: userDetails.is_blocked,
      is_deleted: true,
      language: "ENGLISH",
      account_status: userDetails.account_status,
    };

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result: any) => {
      if (result.isConfirmed) {
        try {
          henceforthApi.User.manageUsers(data)
            .then((res: any) => {
              console.log(res, "response");
              Swal.fire({
                title: "User Deleted",
                text: res.data.message,
                icon: "success",
                confirmButtonText: "OK",
              });
              window.history.back();
            })
            .catch((err: any) => {
              console.log(err, "ERROR");
              Swal.fire({
                title: "Error!",
                text: err,
                icon: "error",
                confirmButtonText: "Cancel",
              });
            });
        } catch (error: any) { }
      }
    });
  };
  useEffect(() => {
    getRideDetalils()
  }, [manageUsers]);

  return (
    <>
      <div className="row animated fadeInRight">
        <div className="col-md-6">
          <div className="ibox">
            <div className="ibox-title">
              <h5>Ride Details</h5>
            </div>
            <div className="ibox-content">
              <div className="upload-image m-auto position-relative">
                <div className="d-flex h-100 justify-content-center align-items-center">
                  <img

                    src={
                      ridedetail?.image
                        ? henceforthApi.FILES.imageOriginal(ridedetail?.image)
                        : profile_placeholder
                    }
                    alt="ProfileImage"
                    className="img-fluid profile-img-wrapper rounded-circle"
                  />
                  <MaskImage image={ridedetail?.image} key={ridedetail?.image} />
                </div>
              </div>
              <div className="mt-4">
                <p className="text-black mb-1 user-text">
                  <b>Name :</b>
                  <span className="text-muted-userD ms-1">
                    {ridedetail?.driver_name
                      ? ` ${ridedetail?.driver_name} `
                      : `Not Available`}
                  </span>
                </p>
                <p className="text-black mb-1 user-text">
                  <b>Pick up :</b>
                  <span className="text-muted-userD ms-1">
                    {ridedetail?.address_from ? ridedetail?.address_from : "Not Available"}
                  </span>
                </p>
                <p className="text-black mb-1 user-text">
                  <b>Drop of :</b>
                  <span className="text-muted-userD ms-1">
                    {ridedetail?.address_to ? ridedetail?.address_to : "Not Available"}
                  </span>
                </p>
                <p className="text-black mb-1 user-text">
                  <b>Seats available :</b>
                  <span className="text-muted-userD ms-1">
                    {ridedetail?.seats_available ? `${ridedetail?.seats_available}` : "not available"}
                  </span>
                </p>
                <p className="text-black mb-1 user-text">
                  <b>Price per seat:</b>
                  <span className="text-muted-userD ms-1">
                    {ridedetail?.price ? ridedetail?.currency == henceofrthEnums.CurrencyType.NGN ? `₦ ${ridedetail?.price}` : `$ ${ridedetail?.price}` : "not available"}
                  </span>
                </p>
                <p className="text-black mb-1 user-text">
                  <b>Date :</b>
                  <span className="text-muted-userD ms-1">
                    {moment(Number(ridedetail?.date)).format("YYYY-MM-DD")}
                  </span>
                </p>
                <p className="text-black mb-1 user-text">
                  <b>ETA :</b>
                  <span className="text-muted-userD ms-1">
                    {ridedetail?.time_taken ? `${ridedetail?.time_taken}` : "not available"}
                  </span>
                </p>
                <p className="text-black mb-1 user-text">
                  <b>Start time:</b>
                  <span className="text-muted-userD ms-1">
                    {ridedetail?.pickup_time ? `${ridedetail?.pickup_time}` : "not available"}
                  </span>
                </p>
                <p className="text-black mb-1 user-text">
                  <b>Car name :</b>
                  <span className="text-muted-userD ms-1">
                    {ridedetail?.vehicle_brand_name ? `${ridedetail?.vehicle_brand_name}` : "not available"}
                  </span>
                </p>
                <hr />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RideDetails
