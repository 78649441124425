import React from "react";
const CountCard = (props:any) => {
  return (
    <div className="ibox float-e-margins">
      <div className="ibox-title">
        <h5>
          {" "}
          <i className={`${props.icon} me-2`}></i>
          {props.title}
        </h5>
      </div>
      <div className="ibox-content">
        <h1 className="no-margins">{props.count} {props.currency}</h1>
      </div>
    </div>
  );
};

export default CountCard;
