import { Link, useMatch } from "react-router-dom";
import { GlobalContext } from "../context/Provider";
import henceforthApi from "../utils/henceforthApi";
import React, { useState, useContext, useEffect } from "react";
import Swal from "sweetalert2";
import moment from "moment";
interface policiesDetail {
  created_at: string,
  description: string,
  image_url: string,
  type: string,
  updated_at: string,
  _id: string,
  title: string
}
export default () => {
  const match: any = useMatch("/policies/:type");

  const { authState, authDispatch, handleError } = useContext(GlobalContext);
  const [state, setState] = useState({
    created_at: "",
    description: "",
    image_url: "",
    type: "",
    updated_at: "",
    _id: "",
    title: ""
  } as policiesDetail);
  if (authState.access_token) { henceforthApi.setToken(authState.access_token); }

  const initialiseData = async () => {
    try {
      let apiRes = await henceforthApi.Policies.byType(match.params.type);
      let obj =
        Array.isArray(apiRes.data) && apiRes.data.length
          ? apiRes.data[0]
          : apiRes.data;
      setState({
        ...state,
        ...obj,
      });

      console.log("image_url", obj);
    } catch (error: any) {
      handleError(error)
    }
  };

  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result: any) => {
      if (result.isConfirmed) {
        try {
          let apiRes = await henceforthApi.Policies.delete(state._id);
          Swal.fire({
            title: "Policy Deleted",
            text: "",
            icon: "success",
            confirmButtonText: "OK",
          });
          window.history.back();
        } catch (error: any) {
          Swal.fire({
            title: "Error!",
            text: "",
            icon: "error",
            confirmButtonText: "Cancel",
          });
        }
      }
    });
  };
  useEffect(() => {
    initialiseData();
  }, []);
  return (
    <div className="animated fadeInRight">
      <div className="ibox">
        <div className="ibox-title">
          <div className="d-flex justify-content-between align-items-center">
            <h3 className="flex-grow-1 text-center text-uppercase">
              {state.title}
            </h3>
            <div className="">
              <Link to="/contents" className="btn btn-primary-button me-3 px-4">
                <i className="fas fa-arrow-left me-1"></i>Back
              </Link>
              <Link
                className="btn btn-primary-button  me-3 px-4"
                to={`/policies/${match.params.type}/edit`}
              >
                <i className="fas fa-edit me-1"></i>Edit
              </Link>

            </div>
          </div>
        </div>
        <div className="ibox-content">
          <p className="text-end ">
            <b>
              Last Updated:
              {moment(Number(state.created_at)).format("MMM DD, YYYY")}
            </b>
          </p>
          <div
            className="word-break-policies-detail text-dark"
            dangerouslySetInnerHTML={{ __html: state?.description }} >
          </div>
          <div className="text-end"></div>
        </div>
      </div>
    </div>
  );
};
