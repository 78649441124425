import React, { useContext, useEffect, useState } from 'react';
import { useMatch, useNavigate, useParams } from 'react-router-dom'
import henceforthApi from '../utils/henceforthApi';
import { GlobalContext } from "../context/Provider";
import Swal from 'sweetalert2';
import PaginationLayout from '../components/PaginationLayout';
import { toast } from "react-toastify";

interface vehicles {
    name: string,
    _id: string
}
interface modal {
    name: string,
    _id: string
}

function DelVehicle() {
    const { authState, handleError } = useContext(GlobalContext);
    const refVehicle = useParams()
    if (authState.access_token) { henceforthApi.setToken(authState.access_token); }
    let match: any = useMatch("vehicle-details/:page/:id")
    const [state, setState] = useState<vehicles>({
        name: '',
        _id: ""
    })
    const pageNumber = match?.params?.page
    const navigate = useNavigate()

    const [modal, setModal] = useState<Array<modal>>([])
    const [brandName, setBrandName] = useState("")
    const [storeModal, setStoreModal] = useState<Array<string>>([])
    const [modelName, setModelName] = useState("")
    const [addModel, setAddModel] = useState("")
    const [bootstrapModalData, setBootstrapModalData] = useState<any>(null)
    const [vehicleData, setVehicleData] = useState<any>()
    const [page, setPage] = useState<any>({})
    let limit = 10
    // let val = document.getElementById("model")?.value
    const getBrand = async () => {
        try {
            let apiRes = await henceforthApi.Vehicle.getBrandById(match.params.id)
            console.log(apiRes)
            setState(apiRes.data.data[0])
            setBrandName(apiRes.data.data[0].name)
            let brandName = apiRes.data.data
            let modalRes = await henceforthApi.Vehicle.getModals(brandName[0].name, pageNumber, "")
            console.log(modalRes)
            setModal(modalRes.data.data)
            setPage(modalRes.data)
        }
        catch (error: any) {
            handleError(error)
        }
        console.log(modelName)
    }
    const EditVehicle = async () => {
        let modelData = modal.map((res) => res.name)
        let modelData1 = storeModal.map(res => res)
        let md = [...modelData, ...modelData1]
        let info = {
            brand_name: state?.name,
            models: md
        }
        await henceforthApi.Vehicle.editVehicle(info)
        getBrand()
        setModelName("")
        setStoreModal([])
        window.history.back()
    }
    const EditBrand = async () => {
        try {
            let brandName = bootstrapModalData?.name.trim()
            if (!brandName) {
                return toast.warn('Spaces are not allowed as character')
            }
            let info = {
                _id: state?._id,
                name: brandName
            }
            await henceforthApi.Vehicle.editBrand(info)
            getBrand()
            console.log("BRAND")

        } catch (error: any) {
            toast.error(error)
        }

    }
    const EditModel = async () => {
        try {
            debugger
            let modelName = bootstrapModalData?.name.trim()
            if (!modelName) {
                return toast.warn('Spaces are not allowed as character')
            }
            console.log("modalName", modelName)
            let info = {
                _id: bootstrapModalData._id,
                name: modelName
            }
            await henceforthApi.Vehicle.editModel(info)
            getBrand()
            setModelName("")
            console.log("MODAL")
        } catch (error: any) {
            toast.error(error)
        }

    }
    const handleDelete = async (id: string) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result: any) => {
            if (result.isConfirmed) {
                try {
                    henceforthApi.Vehicle.delModal(id).then((res: any) => {
                        Swal.fire({
                            title: "Model Deleted",
                            text: "Model deleted successfully",
                            icon: "success",
                            confirmButtonText: "OK",
                        })
                        getBrand()
                        window.history.back()
                    }).catch((err: any) => {
                        console.log("delte failed", err);
                        Swal.fire({
                            title: "Model Delete Failed",
                            text: err.response.body.error_description,
                            icon: "error",
                            confirmButtonText: "Cancel",
                        });
                    });
                }
                catch (err: any) { console.log(err) }
            }
        })
    }
    const AddModal = () => {
        setStoreModal([...storeModal, addModel])
        setAddModel("")
        // document.getElementById("span").style.display = "none"
    }
    const brandDelete = async (id: string) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result: any) => {
            if (result.isConfirmed) {
                try {
                    henceforthApi.Vehicle.delBrand(id).then((res: any) => {
                        Swal.fire({
                            title: "Contact Deleted",
                            text: "Contact deleted successfully",
                            icon: "success",
                            confirmButtonText: "OK",
                        })
                        getBrand()
                        window.history.back()
                    }).catch((err: any) => {
                        console.log("delte failed", err);
                        Swal.fire({
                            title: "Contact Delete Failed",
                            text: err.response.body.error_description,
                            icon: "error",
                            confirmButtonText: "Cancel",
                        });
                    });
                }
                catch {
                    console.log("error")
                }
            }
        })
    }
    useEffect(() => {
        getBrand()
    }, [match?.params?.page])

    const updateData = async () => {
        setBootstrapModalData(null)
    }
    const handlePagination = (page: any) => {
        // const newParams =new URLSearchParams(location.search)
        if (page) {
            navigate(`/vehicle-details/${page}/${match?.params?.id}`)
        } else {
            navigate(`/vehicle-details/1/${match?.params?.id}`)
        }
    }
    return (
        <div>
            <div className="table-responsive mt-3">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Sr.</th>
                            <th>Vehicle Brand</th>
                            <th></th>
                            {state?.name ? <th>Action</th> : ""}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td> {state?.name ? state?.name : "Brand not available"}  </td>
                            <td></td>
                            <td>
                                <div className="btn-group">
                                    <button
                                        className="btn btn-xs btn-white "
                                        onClick={() => setBootstrapModalData({ ...bootstrapModalData, ...state, type: "BRAND" })}>
                                        <i className="fas fa-edit me-1"></i>Edit
                                    </button>
                                </div>
                            </td>
                            <td>
                                {/* {state?.name ? <div className="btn-group">
                                    <button
                                        onClick={() => brandDelete(state._id)}
                                        className="btn-primary-button  btn btn-xs me-2">
                                        <i className="fas fa-trash  me-1"></i>Delete
                                    </button>
                                </div> : ""} */}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {/* vehicle brand  */}
            <div className="table-responsive mt-3">
                <table className="table table-striped">
                    <thead >
                        <tr>
                            <th>Sr.</th>
                            <th>Vehicle Model</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {state?.name && modal.length ?
                            modal.map((res, index: number) => {
                                return (
                                    <tr key={res?._id} onMouseOver={() => setVehicleData(res)}>
                                        <td>{Number(match?.params?.page) == 0 ? index + 1 : (Number(match?.params?.page) - 1) * limit + (index + 1)}</td>
                                        <td>{res?.name ? res?.name : ""}</td>
                                        <td>
                                            <div className="btn-group">
                                                <button
                                                    className="btn btn-xs btn-white me-2"

                                                    onClick={() => setBootstrapModalData({ ...bootstrapModalData, ...res, type: "MODAL" })}>
                                                    <i className="fas fa-edit me-1" ></i>Edit
                                                </button>
                                            </div>
                                        </td>
                                        {/* <td>
                                            <div className="btn-group">
                                                <button
                                                    onClick={() => handleDelete(res._id)}
                                                    className="btn-primary-button  btn btn-xs me-2">
                                                    <i className="fas fa-trash  me-1"></i>Delete
                                                </button>
                                            </div>
                                        </td> */}
                                    </tr>
                                )
                            })
                            : <h2 className='mt-5 text-center'><b className='text-center'>No model available</b></h2>}

                    </tbody>
                </table>
                <PaginationLayout
                    count={Number(page.total_count)}
                    data={modal}
                    page={Number(pageNumber)}
                    limit={Number(10)}
                    loading={false}
                    onPageChange={(val) => handlePagination(val)}
                />
                <div className='row mt-3 mb-2'>
                    <div className='col-4 '>
                        <label><b> Vehicle Model</b></label>
                        <input type="text" value={addModel} onChange={(e: any) => setAddModel(e.target.value)} className='form-control mt-2' placeholder='Enter model' />
                    </div>
                    <div className='col-2'>
                        <button className='btn btn-color mt-4' type='button' onClick={AddModal} disabled={!addModel.trim()} >Add model</button>
                    </div>
                    <div className='col-8 font mt-2'>
                        <br></br>
                        {storeModal.map((item) => {
                            return <div> <label> Vehicle Model : </label> {item}</div>
                        })}
                    </div>
                </div>
                <div className='col-3 mb-5'>
                    <button type="submit" className='btn btn-color mt-4' disabled={!storeModal.length} onClick={EditVehicle}>Save Changes</button>
                </div>
            </div>
            <div className={`modal fade common-modal  report-modal ${bootstrapModalData ? 'show' : ''}`} data-bs-backdrop="static" tabIndex={-1} role={bootstrapModalData ? 'dialog' : ''} style={{ display: bootstrapModalData ? 'block' : 'none', backgroundColor: 'rgba(0,0,0,0.5)' }} aria-hidden="true">
                <div className="modal-dialog">
                    <form className="modal-content" onSubmit={(e: any) => { e.preventDefault(); updateData() }}>
                        <div className="modal-header">
                            <h4 className="modal-title">{bootstrapModalData?.type == "MODAL" ? "Edit Model Name" : "Edit Brand Name"} </h4>
                            <button type="button" className="btn-close" onClick={() => setBootstrapModalData(null)}></button>
                        </div>
                        <div className="modal-body">
                            {bootstrapModalData?.type == "MODAL" ?
                                <div className=''>
                                    <label> Model Name</label>
                                    <input type="text" onChange={(e: any) => { setBootstrapModalData({ ...bootstrapModalData, name: e.target.value, type: "MODAL" }) }} value={bootstrapModalData?.name} className='form-control mt-2' placeholder='Enter modal' />
                                </div> :
                                <div className=''>
                                    <label> Brand Name</label>
                                    <input type="text" onChange={(e: any) => { setBootstrapModalData({ ...bootstrapModalData, name: e.target.value, type: "BRAND" }) }} value={bootstrapModalData?.name} className='form-control mt-2' placeholder='Enter modal' />
                                </div>
                            }
                        </div>
                        <div className="modal-footer">
                            <div className="btn-group">
                                {bootstrapModalData?.type == "MODAL" ? <button
                                    onClick={() => handleDelete(vehicleData?._id)}
                                    className="btn-primary-button p-2 btn btn-xs me-2">
                                    <i className="fas fa-trash  me-1"></i>Delete
                                </button> : <button
                                    onClick={() => brandDelete(state._id)}
                                    className="btn-primary-button  btn btn-xs pt-2  me-2">
                                    <i className="fas fa-trash  me-1"></i>Delete
                                </button>}
                            </div>
                            <button type="submit" className="btn  btn-primary-button" onClick={bootstrapModalData?.type == "MODAL" ? EditModel : EditBrand} >Post</button>
                        </div>

                    </form>

                </div>
            </div>

        </div >
    )
}

export default DelVehicle
